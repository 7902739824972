import * as types from './types';
import uniq from 'lodash/uniq';
import { copyArray } from 'Common/Functions/Copy';

export const initialState = {
  isFetching: false,
  error: false,
  errorMessage: '',
  list: [],
  showServiceDetail: false,
  serviceDetail: undefined,
  servicesSelected: [],
  lastSelected: -1,
  showDeleteConfirmation: false
};

const servicesGeneral = (state = initialState, action) => {
  switch (action.type) {
    case types.GENERAL_SERVICES_FETCHING: {
      return {
        ...state,
        isFetching: true
      };
    }
    case types.GENERAL_SERVICES_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.errorMessage
      };
    }
    case types.GENERAL_SERVICES_CLEAR_ERROR: {
      return {
        ...state,
        error: false,
        errorMessage: ''
      };
    }
    case types.GENERAL_SERVICES_GET_SUCCESS: {
      return {
        ...state,
        list: action.data,
        count: action.count,
        isFetching: false
      };
    }
    case types.GENERAL_SERVICES_SELECTED: {
      return {
        ...state,
        servicesSelected: uniq([...state.servicesSelected, ...action.payload]),
        serviceSelectedForWorking: uniq([...state.servicesSelected, ...action.payload])
      };
    }
    case types.GENERAL_SERVICES_UNSELECTED: {
      const filterByID = (array, id) => array.filter((el) => el._id !== id);
      const newServicesSelected = action.payload.reduce((servicesSelected, serviceToDelete) => filterByID(servicesSelected, serviceToDelete._id), state.servicesSelected);

      return {
        ...state,
        servicesSelected: newServicesSelected
      };
    }
    case types.GENERAL_SERVICES_NEW_PIVOT: {
      return {
        ...state,
        lastSelected: action.payload
      };
    }
    case types.GENERAL_SERVICES_SELECT_ALL: {
      return {
        ...state,
        servicesSelected: [...action.servicesList]
      };
    }
    case types.GENERAL_SERVICES_UNSELECT_ALL: {
      return {
        ...state,
        servicesSelected: []
      };
    }
    case types.GENERAL_SERVICES_SHOW_DETAIL: {
      return {
        ...state,
        serviceDetail: action.service,
        showServiceDetail: true
      };
    }
    case types.GENERAL_SERVICES_HIDE_DETAIL: {
      return {
        ...state,
        serviceDetail: undefined,
        showServiceDetail: false
      };
    }
    case types.GENERAL_SERVICES_SHOW_MODAL_DELETE: {
      return {
        ...state,
        showDeleteConfirmation: true
      };
    }
    case types.GENERAL_SERVICES_HIDE_MODAL_DELETE: {
      return {
        ...state,
        showDeleteConfirmation: false
      };
    }
    case types.GENERAL_SERVICES_DELETE_SUCCESS: {
      return {
        ...state,
        servicesSelected: [],
        isFetching: false
      };
    }
    case types.GENERAL_SERVICES_DELETE_FAIL: {
      return {
        ...state,
        isFetching: false
      };
    }
    case types.GENERAL_SERVICES_UPDATE_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        list: action.data,
        serviceCount: action.serviceCount
      };
    }
    case types.GENERAL_SERVICES_RESET_EDIT: {
      return {
        ...state,
        serviceDetail: state.initialState
      };
    }
    case types.GENERAL_SERVICES_RESET_STATE: {
      return {
        serviceSelectedForWorking: state.serviceSelectedForWorking,
        ...initialState
      };
    }
    case types.GENERAL_SERVICES_ADD_SERVICE_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        list: [...action.services, ...state.list],
        count: state.count + action.services.length
      };
    }
    case types.GET_ASSETS_BY_WORKSPACE_REQUEST: {
      return {
        ...state,
        hosts: {
          ...state.hosts,
          isFetching: true
        }
      };
    }
    case types.GET_ASSETS_BY_WORKSPACE_SUCCESS: {
      return {
        ...state,
        hosts: {
          ...state.hosts,
          isFetching: false,
          list: action.data
        }
      };
    }
    case types.GET_ASSETS_BY_WORKSPACE_FAIL: {
      return {
        ...state,
        hosts: {
          ...state.hosts,
          isFetching: false
        },
        errorMessage: action.error
      };
    }
    case types.GENERAL_SERVICES_SET_TAGS_SUCCESS: {
      return {
        ...state,
        isFetching: false
      };
    }
    case types.GENERAL_SERVICES_SET_TAGS_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.error
      };
    }

    case types.GENERAL_SERVICES_SELECT_TOTAL: {
      return {
        ...state,
        selectAll: true
      };
    }
    case types.GENERAL_SERVICES_UNSELECT_TOTAL: {
      return {
        ...state,
        selectAll: false
      };
    }
    case types.GENERAL_SERVICES_UNSELECTED_ROW_FROM_SELECT_ALL: {
      return {
        ...state,
        unselectedRowFromSelectAll: action.value
      };
    }
    case types.GENERAL_SERVICES_AUTOSELECT: {
      const servicesSelected = copyArray(state.servicesSelected);
      servicesSelected.push(action.service);
      return {
        ...state,
        servicesSelected
      };
    }

    default: {
      return state;
    }
  }
};

export default servicesGeneral;
