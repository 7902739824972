import { copy, copyArray } from 'Common/Functions/Copy';
import * as types from './types';
import initialState from 'store/Contextualization/AssetDetail/initialState';
import uniq from 'lodash/uniq';
import get from 'lodash/get';

function assetDetail (state = initialState, action) {
  const { entity } = action;
  let entityInitialState;
  let entityState;

  if (entity) {
    entityInitialState = get(initialState, entity, undefined);
    entityState = get(state, entity, undefined);
  }

  switch (action.type) {
    case types.RESET_STATE_HOST_DETAIL: {
      return {
        ...copy(initialState)
      };
    }
    case types.SHOW_HOST_DETAIL: {
      return {
        ...state,
        host: action.host
      };
    }
    case types.HIDE_HOST_DETAIL: {
      return {
        ...state,
        host: null
      };
    }
    case types.GET_DATA_VULNS_START: {
      return {
        ...state,
        vulns: {
          ...state.vulns,
          isFetching: true,
          data: []
        }
      };
    }
    case types.GET_DATA_VULNS_SUCCESS: {
      return {
        ...state,
        vulns: {
          ...state.vulns,
          isFetching: false,
          data: action.data,
          count: action.count
        }
      };
    }
    case types.GET_DATA_VULNS_FAIL: {
      return {
        ...state,
        vulns: {
          ...state.vulns,
          isFetching: false,
          data: [],
          error: true,
          errorMessage: action.data
        }
      };
    }
    case types.GET_DATA_SERVICES_START: {
      return {
        ...state,
        services: {
          ...state.services,
          isFetching: true,
          data: []
        }
      };
    }
    case types.GET_DATA_SERVICES_SUCCESS: {
      return {
        ...state,
        services: {
          ...state.services,
          isFetching: false,
          data: action.data,
          count: action.count
        }
      };
    }
    case types.GET_DATA_SERVICES_FAIL: {
      return {
        ...state,
        services: {
          ...state.services,
          isFetching: false,
          data: []
        },
        error: true,
        errorMessage: action.data
      };
    }
    case types.GET_DATA_TOOLS_START: {
      return {
        ...state,
        tools: {
          ...state.tools,
          isFetching: true,
          data: []
        }
      };
    }
    case types.GET_DATA_TOOLS_SUCCESS: {
      return {
        ...state,
        tools: {
          ...state.tools,
          isFetching: false,
          data: action.data
        }
      };
    }
    case types.GET_DATA_TOOLS_FAIL: {
      return {
        ...state,
        tools: {
          ...state.tools,
          isFetching: false,
          data: []
        }
      };
    }
    case types.ADD_SERVICE_ON_ASSET_FAIL: {
      return {
        ...state,
        services: {
          ...state.services,
          error: true,
          errorMessage: action.message
        }
      };
    }
    case types.ADD_SERVICE_ON_ASSET_SUCCESS: {
      return {
        ...state,
        services: {
          ...state.services,
          isFetching: false,
          data: [action.service, ...state.services.data]

        }
      };
    }

    case types.SELECT_SERVICE: {
      return {
        ...state,
        createOrEditService: {
          ...state.createOrEditService,
          selectedService: action.selectedRow
        }
      };
    }

    case types.UPDATE_VULN_ASSET_PREVIEW_SUCCESS: {
      return {
        ...state,
        vulns: {
          ...state.vulns,
          data: action.data,
          isFetching: false,
          count: action.vulnsCount,
          detail: action.vuln
        }
      };
    }
    case types.UPDATE_VULN_ASSET_PREVIEW_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.errorMessage
      };
    }
    case types.ASSET_DETAIL_SERVICE_SELECTED: {
      return {
        ...state,
        services: {
          ...state.services,
          selected: uniq([...state.services.selected, ...action.payload]),
          selectedForWorking: uniq([...state.services.selected, ...action.payload])
        }
      };
    }
    case types.ASSET_DETAIL_SERVICE_UNSELECTED: {
      const filterByID = (array, id) => array.filter((el) => el._id !== id);
      const newServicesSelected = action.payload.reduce((servicesSelected, serviceToDelete) => filterByID(servicesSelected, serviceToDelete._id), state.services.selected);

      return {
        ...state,
        services: {
          ...state.services,
          selected: newServicesSelected
        }
      };
    }
    case types.ASSET_DETAIL_NEW_PIVOT: {
      return {
        ...state,
        [entity]: {
          ...entityState,
          lastSelected: action.payload
        }
      };
    }
    case types.SELECT_ALL_ITEMS: {
      return {
        ...state,
        [entity]: {
          ...entityState,
          selected: [...action.list]
        }
      };
    }
    case types.UNSELECT_ALL_ITEMS: {
      return {
        ...state,
        [entity]: {
          ...entityState,
          selected: []
        }
      };
    }
    case types.SHOW_DETAIL: {
      return {
        ...state,
        [entity]: {
          ...entityState,
          detail: action.detail,
          showDetail: true
        }
      };
    }
    case types.HIDE_DETAIL: {
      return {
        ...state,
        [entity]: {
          ...entityState,
          detail: undefined,
          showDetail: false
        }
      };
    }
    case types.CLEAR_ERROR: {
      return {
        ...state,
        [entity]: {
          ...entityState,
          error: false,
          errorMessage: ''
        }
      };
    }
    case types.SET_PAGE_NUMBER_SERVICES: {
      return {
        ...state,
        services: {
          ...state.services,
          page: action.pageNumber
        }
      };
    }
    case types.SHOW_MODAL_DELETE: {
      return {
        ...state,
        [entity]: {
          ...entityState,
          showDeleteConfirmation: true
        }
      };
    }
    case types.HIDE_MODAL_DELETE: {
      return {
        ...state,
        [entity]: {
          ...entityState,
          showDeleteConfirmation: false
        }
      };
    }
    case types.DELETE_START: {
      return {
        ...state,
        [entity]: {
          ...entityState,
          showDeleteConfirmation: false,
          isFetching: true
        }
      };
    }
    case types.DELETE_SUCCESS: {
      return {
        ...state,
        [entity]: {
          ...entityState,
          selected: [],
          isFetching: false
        }
      };
    }
    case types.DELETE_FAIL: {
      return {
        ...state,
        [entity]: {
          ...entityState,
          isFetching: false
        }
      };
    }
    case types.RESET_SERVICE_DETAIL: {
      return {
        ...state,
        services: {
          ...state.services,
          detail: undefined
        }
      };
    }
    case types.ASSET_DETAIL_UPDATE_SERVICE_SUCCESS: {
      return {
        ...state,
        services: {
          ...state.services,
          isFetching: false,
          data: action.data,
          count: action.serviceCount
        }
      };
    }
    case types.RESET_ASSET_STATE: {
      return {
        ...state,
        [entity]: {
          selectedForWorking: entityState.selectedForWorking,
          ...entityInitialState
        }
      };
    }
    case types.SET_VULNS_TAB: {
      return {
        ...state,
        vulns: {
          ...state.vulns,
          selectedTab: action.vulnSelectedTab
        }
      };
    }
    case types.ASSET_DETAIL_VULN_SELECTED: {
      return {
        ...state,
        vulns: {
          ...state.vulns,
          selected: uniq([...state.vulns.selected, ...action.payload]),
          selectedForWorking: uniq([...state.vulns.selected, ...action.payload])
        }
      };
    }
    case types.VULN_UNSELECTED: {
      const filterByID = (array, id) => array.filter((el) => el._id !== id);
      const newVulnsSelected = action.payload.reduce((vulnsSelected, vulnToDelete) => filterByID(vulnsSelected, vulnToDelete._id), state.vulns.selected);

      return {
        ...state,
        vulns: {
          ...state.vulns,
          selected: newVulnsSelected
        }
      };
    }
    case types.SHOW_VULNS_SIDEBAR_FILTERS: {
      return {
        ...state,
        vulns: {
          ...state.vulns,
          showFiltersSidebar: action.visible
        }
      };
    }
    case types.SET_HOST_FOR_WORKING: {
      return {
        ...state,
        hostForWorking: action.host
      };
    }
    case types.CREATE_VULN_FROM_ASSET: {
      const vulnsList = state.vulns.data;
      return {
        ...state,
        vulns: {
          ...state.vulns,
          data: [...action.vulns, ...vulnsList]
        }
      };
    }

    case types.VULN_ASSET_ERROR: {
      return {
        ...state,
        vulns: {
          ...state.vulns,
          error: true,
          errorMessage: action.errorMessage
        }
      };
    }

    case types.VULN_ASSET_SET_BULK_UPDATE_FIELD: {
      return {
        ...state,
        vulns: {
          ...state.vulns,
          bulkUpdateField: action.field,
          bulkUpdateValue: ''
        }
      };
    }

    case types.VULN_ASSET_SET_BULK_UPDATE_VALUE: {
      return {
        ...state,
        vulns: {
          ...state.vulns,
          bulkUpdateValue: action.value
        }
      };
    }

    case types.VULN_ASSET_ADD_BULK_UPDATE_VALUE: {
      const bulkUpdateValue = state.vulns.bulkUpdateValue;
      bulkUpdateValue.push(action.value);

      return {
        ...state,
        vulns: {
          ...state.vulns,
          bulkUpdateValue
        }
      };
    }

    case types.VULN_ASSET_REMOVE_BULK_UPDATE_VALUE: {
      let bulkUpdateValue = state.bulkUpdateValue;
      bulkUpdateValue = bulkUpdateValue.filter((element) => element !== action.value);

      return {
        ...state,
        vulns: {
          ...state.vulns,
          bulkUpdateValue
        }
      };
    }

    case types.VULN_ASSET_BULK_UPDATE_FINISHED: {
      return {
        ...state,
        vulns: {
          ...state.vulns,
          isFetching: false,
          bulkUpdateField: '',
          bulkUpdateValue: ''
        }
      };
    }

    case types.VULN_ASSET_SET_BULK_UPDATE_SUCCESS: {
      return {
        ...state,
        vulns: {
          ...state.vulns,
          isFetching: false,
          isFetchingExpandedData: false,
          error: false,
          errorMessage: ''
        }
      };
    }

    case types.VULNS_CONFIRMATION_CHANGE_START: {
      return {
        ...state,
        vulns: {
          ...state.vulns,
          isFetching: true,
          error: false,
          errorMessage: ''
        }
      };
    }

    case types.VULNS_ASSET_REFRESH_VULNS: {
      return {
        ...state,
        vulns: {
          ...state.vulns,
          data: action.vulnsList,
          count: action.vulnsCount,
          selectedVulns: action.vulnsSelected,
          detail: action.vulnDetail
        }
      };
    }

    case types.SELECT_TOTAL_ITEMS: {
      return {
        ...state,
        [entity]: {
          ...entityState,
          selectAll: true
        }
      };
    }

    case types.UNSELECT_TOTAL_ITEMS: {
      return {
        ...state,
        [entity]: {
          ...entityState,
          selectAll: false
        }
      };
    }

    case types.UNSELECTED_ROW_ITEM_FROM_SELECT_ALL: {
      return {
        ...state,
        [entity]: {
          ...entityState,
          unselectedRowFromSelectAll: action.value
        }
      };
    }

    case types.AUTOSELECT_VULN_ITEM : {
      const selected = copyArray(state.vulns.selected);
      selected.push(action.item);
      return {
        ...state,
        vulns: {
          ...state.vulns,
          selected
        }
      };
    }

    case types.VULNS_ASSET_SHOW_CONTEXT_MENU: {
      return {
        ...state,
        vulns: {
          ...state.vulns,
          showContextMenu: action.show,
          contextMenuXPos: action.contextMenuXPos,
          contextMenuYPos: action.contextMenuYPos
        }
      };
    }

    case types.SET_TAGS_SUCCESS: {
      return {
        ...state,
        [entity]: {
          ...entityState,
          isFetching: false
        }
      };
    }
    case types.SET_TAGS_FAIL: {
      return {
        ...state,
        [entity]: {
          ...entityState,
          isFetching: false,
          error: true,
          errorMessage: action.error
        }
      };
    }
    case types.TAGS_UPDATE_DETAIL: {
      return {
        ...state,
        [entity]: {
          ...entityState,
          isFetching: false,
          detail: { ...action.detail }
        }
      };
    }

    case types.DUPLICATES_GET_SUCCESS: {
      return {
        ...state,
        vulns: {
          ...state.vulns,
          error: false,
          detailDuplicates: action.duplicates
        }
      };
    }
    case types.DUPLICATES_MARK_AS_MAIN_SUCCESS: {
      return {
        ...state,
        vulns: {
          ...state.vulns,
          error: false
        }
      };
    }
    case types.DUPLICATES_MARK_SUCCESS: {
      return {
        ...state,
        vulns: {
          ...state.vulns,
          error: false,
          data: action.updatedVulnList
        }
      };
    }
    case types.DUPLICATES_REMOVE_SUCCESS: {
      return {
        ...state,
        vulns: {
          ...state.vulns,
          error: false
        }
      };
    }
    case types.DUPLICATES_ERROR: {
      return {
        ...state,
        vulns: {
          ...state.vulns,
          error: true,
          errorMessage: action.error
        }
      };
    }

    case types.VULNS_ASSET_REFRESH_VULN_ENRICHMENT_REQUEST: {
      return {
        ...state,
        vulns: {
          ...state.vulns,
          isFetchingVulnEnrichment: true
        }
      };
    }
    case types.VULNS_ASSET_REFRESH_VULN_ENRICHMENT_SUCCESS: {
      return {
        ...state,
        vulns: {
          ...state.vulns,
          isFetchingVulnEnrichment: false
        }
      };
    }
    case types.VULNS_ASSET_REFRESH_VULN_ENRICHMENT_FAILURE: {
      return {
        ...state,
        vulns: {
          ...state.vulns,
          isFetchingVulnEnrichment: false,
          bulkUpdateValue: '',
          error: true,
          errorMessage: action.errorMessage
        }
      };
    }

    case types.VULNS_ASSET_UPDATE_VULN_CLIENT_SIDE: {
      const { updatedVuln, toolName } = action.payload;
      const id = updatedVuln.vuln_id;

      const vulnsListArray = state.vulns.data.map(vuln => {
        if (vuln.id === id) {
          return {
            ...vuln,
            issuetracker: {
              ...vuln.issuetracker,
              [toolName]: vuln.issuetracker[toolName] ? [...vuln.issuetracker[toolName], updatedVuln] : [updatedVuln]
            }
          };
        }
        return vuln;
      });

      return {
        ...state,
        vulns: {
          ...state.vulns,
          data: vulnsListArray
        }
      };
    }

    default: {
      return state;
    }
  }
}

export default assetDetail;
