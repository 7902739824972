/* eslint-disable no-useless-constructor */
import React from 'react';
import capitalize from 'lodash/capitalize';
import PropTypes from 'prop-types';
import Dropdown from 'Common/Components/Dropdown';
import { standardDropdownStyle } from 'Common/styles/style';
import { Wrapper } from './styled';

const changeStyle = (newWidth) => {
  const newStyle = { ...standardDropdownStyle };
  const newControl = { ...newStyle.control(), width: newWidth };
  newStyle.control = () => newControl;
  return newStyle;
};

const Item = ({ title, normal }) => (
  <div className="d-inline-flex item-option">
    <span>{normal ? title : capitalize(title)}</span>
  </div>
);

Item.propTypes = {
  title: PropTypes.string.isRequired
};

export default class StandardDropdown extends React.Component {
  constructor (props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange (newValue) {
    const {
      field, updateValue, selectObject, options
    } = this.props;
    if (selectObject) {
      if (newValue.id) { // In case there can be multiple options with the same name
        const selected = options.find((o) => o.id === newValue.id);
        updateValue(field, selected);
      } else {
        updateValue(field, newValue);
      }
    } else {
      updateValue(field, newValue);
    }
  }

  render () {
    const {
      options, width, defaultValue, placeholder, disabled, isSearchable, menuPortalTarget, normal, selectObject, id, customStyle = null
    } = this.props;
    let style;

    if (width) {
      style = changeStyle(width);
    } else if (customStyle) {
      style = customStyle;
    } else {
      style = standardDropdownStyle;
    }

    const dropdownOptions = options.map((element) => ({ label: <Item title={ element.desc } normal={ normal } />, value: element.name, id: element.id ? element.id : undefined }));
    return (
      <Wrapper>
        <Dropdown
          customStyle={ style }
          options={ dropdownOptions }
          updateValue={ this.onChange }
          placeholder={ placeholder }
          defaultValue={ defaultValue }
          disabled={ disabled }
          menuPortalTarget={ menuPortalTarget }
          selectObject={ selectObject }
          isSearchable={ isSearchable }
          id={ id }
        />
      </Wrapper>
    );
  }
}

StandardDropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    desc: PropTypes.string
  })).isRequired,
  width: PropTypes.string,
  field: PropTypes.string.isRequired,
  updateValue: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  selectObject: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  menuPortalTarget: PropTypes.node,
  normal: PropTypes.bool,
  id: PropTypes.string
};

StandardDropdown.defaultProps = {
  width: '',
  defaultValue: '',
  selectObject: false,
  placeholder: 'Select',
  disabled: false,
  menuPortalTarget: undefined,
  normal: false,
  id: '',
  isSearchable: false
};
