import colors from 'Styles/colors';
import './variables.scss';

function getSeverityColor (value) {
  switch (value) {
    case 'critical':
      return colors.warmPurple;
    case 'high':
      return colors.redPink;
    case 'medium':
      return colors.tangerine;
    case 'med':
      return colors.tangerine;
    case 'low':
      return colors.apple;
    case 'informational':
      return colors.grey2;
    case 'info':
      return colors.grey2;
    case 'unclassified':
      return colors.grey4;
    default:
      return colors.grey4;
  }
}

export const severitySelect = {
  control: (provided, state) => ({
    height: '34px',
    borderRadius: '2px',
    boxShadow: '2px 2px 5px 0 rgb(0 0 0 / 6%), inset 0 -2px 0 0 #696969',
    border: `solid 1px ${getSeverityColor(state.getValue()[0].value)}`,
    backgroundColor: state.getValue()[0] ? getSeverityColor(state.getValue()[0].value) : '#fff',
    color: 'white',
    left: '16px',
    width: '148px'
  }),

  valueContainer: () => ({
    padding: '7px 8px 2px 8px'
  }),

  indicatorSeparator: () => ({
    display: 'none'
  }),

  dropdownIndicator: () => ({
    position: 'absolute',
    top: '3px',
    right: '11px'
  }),

  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
    textAlign: 'left',
    color: '#1c2243',
    cursor: state.isSelected ? 'default' : 'pointer',
    paddingLeft: '22px',
    height: '36px',
    textTransform: 'none',
    fontWeight: state.selectProps.value.value === state.value ? 500 : 300,
    backgroundColor: state.isFocused /* || state.isSelected */ ? '#f1f4f7' : 'white'
  }),

  menu: () => ({
    position: 'absolute',
    marginTop: '6px',
    zIndex: 5000,
    width: '100%',
    border: 'none',
    borderRadius: '3px',
    backgroundColor: '#fff',
    boxShadow: '2px 2px 30px 0 #d5dee6',
    minWidth: '190px'
  }),

  menuList: () => ({
    maxHeight: '250px',
    overflowX: 'auto',
    borderRadius: '3px',
    paddingTop: '17px',
    paddingBottom: '13px'
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return {
      ...provided, opacity, transition, color: 'white', width: 'fit-content', height: '21px', fontSize: '13.5px', fontWeight: 500, left: '12px', textTransform: 'capitalize'
    };
  }
};

export const basicSelect = {
  control: () => ({
    height: '34px',
    border: 'solid 1px #dbdbdb',
    boxShadow: '2px 2px 5px 0 rgba(0, 0, 0, 0.06), inset 0 -2px 0 0 #696969',
    backgroundColor: '#ffffff',
    opacity: '0.6',
    left: '16px',
    fontSize: '12.5px',
    fontWeight: '500',
    color: '#082731',
    width: '162px'
  }),

  indicatorSeparator: () => ({
    display: 'none'
  }),

  dropdownIndicator: () => ({
    position: 'absolute',
    top: '7px',
    right: '6px'
  }),

  option: (provided, state) => ({
    ...provided,
    // backgroundColor: state.isSelected ? '#f1f4f7' : 'white',
    fontSize: '14px',
    textAlign: 'left',
    color: '#1c2243',
    cursor: state.isSelected ? 'default' : 'pointer',
    paddingLeft: '22px',
    height: '36px',
    fontWeight: state.selectProps.value.value === state.value ? 900 : 400,
    backgroundColor: state.isFocused /* || state.isSelected */ ? '#f1f4f7' : 'white'
  }),

  menu: () => ({
    position: 'absolute',
    zIndex: 5000,
    border: 'none',
    borderRadius: '3px',
    backgroundColor: '#fff',
    boxShadow: '2px 2px 30px 0 #d5dee6',
    minWidth: '150px',
    width: '100%'
  }),

  valueContainer: () => ({
    top: '4px'
  }),

  menuList: () => ({
    maxHeight: '250px',
    overflowX: 'auto',
    borderRadius: '3px',
    paddingTop: '17px',
    paddingBottom: '13px'
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return {
      ...provided, opacity, transition, color: '#082731', width: 'fit-content', fontSize: '13.5px', fontWeight: 500, left: '16px'
    };
  }
};

// Modal Dropdown styles
export const severityDropdownStyle = {
  control: (provided, state) => ({
    height: '35px',
    width: '140px',
    borderRadius: '2px',
    boxShadow: '2px 2px 5px 0 rgba(0, 0, 0, 0.06), inset 0 -2px 0 0 #696969',
    backgroundColor: state.getValue()[0] ? getSeverityColor(state.getValue()[0].value) : '#fff',
    border: 'solid 1px #fafbfc',
    color: 'white',
    paddingLeft: '10px',
    fontWeight: '700'
  }),

  indicatorSeparator: () => ({
    display: 'none'
  }),

  placeholder: () => ({
    fontSize: '13px',
    color: '#1c2243',
    position: 'relative',
    fontWeight: 400
  }),

  dropdownIndicator: (provided, state) => ({
    ...provided,
    position: 'absolute',
    top: '0px',
    right: '4px',
    color: state.hasValue ? 'white' : '#515a65',
    width: '30px'
  }),

  option: (provided, state) => ({
    ...provided,
    backgroundColor: 'white',
    fontSize: '14px',
    textAlign: 'left',
    color: '#1c2243',
    cursor: state.isSelected ? 'default' : 'pointer',
    paddingLeft: '22px',
    height: '36px',
    textTransform: 'none',
    fontWeight: state.isSelected ? 600 : 300
  }),

  valueContainer: () => ({
    height: '33px',
    display: 'flex',
    alignItems: 'center'
  }),

  menu: () => ({
    position: 'absolute',
    marginTop: '6px',
    zIndex: 5000,
    width: '100%',
    border: 'none',
    borderRadius: '3px',
    backgroundColor: '#fff',
    boxShadow: '2px 2px 30px 0 #d5dee6',
    minWidth: '190px'
  }),

  menuList: () => ({
    maxHeight: '250px',
    overflowX: 'auto',
    borderRadius: '3px',
    paddingTop: '17px',
    paddingBottom: '13px'
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return {
      ...provided, opacity, transition, color: 'white', width: 'fit-content', height: '19px', fontSize: '14px', fontWeight: 400, top: '8px', position: 'relative', maxWidth: 'calc(100% - 24px)'
    };
  }
};

export const eorDropdownStyle = {
  control: () => ({
    height: '35px',
    width: '196px',
    borderRadius: '2px',
    boxShadow: '2px 2px 5px 0 rgba(0, 0, 0, 0.06), inset 0 -2px 0 0 #696969',
    borderColor: '#d6d6d6',
    color: 'white',
    paddingLeft: '10px',
    fontWeight: '700',
    borderWidth: '0px',
    borderStyle: 'solid'
  }),

  indicatorSeparator: () => ({
    display: 'none'
  }),

  placeholder: () => ({
    fontSize: '13px',
    color: '#1c2243',
    position: 'relative',
    fontWeight: 400
  }),

  dropdownIndicator: (provided) => ({
    ...provided,
    position: 'absolute',
    top: '0px',
    right: '4px',
    color: '#515a65',
    width: '30px'
  }),

  valueContainer: () => ({
    height: '33px',
    display: 'flex',
    alignItems: 'center'
  }),

  option: (provided, state) => ({
    ...provided,
    backgroundColor: 'white',
    fontSize: '14px',
    textAlign: 'left',
    color: '#1c2243',
    cursor: state.isSelected ? 'default' : 'pointer',
    paddingLeft: '22px',
    height: '36px',
    textTransform: 'none',
    fontWeight: state.isSelected ? 600 : 300
  }),

  menu: () => ({
    position: 'absolute',
    marginTop: '6px',
    zIndex: 5000,
    width: '100%',
    border: 'none',
    borderRadius: '3px',
    backgroundColor: '#fff',
    boxShadow: '2px 2px 30px 0 #d5dee6',
    minWidth: '190px'
  }),

  menuList: () => ({
    maxHeight: '250px',
    overflowX: 'auto',
    borderRadius: '3px',
    paddingTop: '17px',
    paddingBottom: '13px'
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return {
      ...provided, opacity, transition, color: '#1c2243', width: 'fit-content', height: '21px', fontSize: '14px', fontWeight: 400, top: '7px', position: 'relative', maxWidth: 'calc(100% - 24px)'
    };
  }
};

export const groupSelect = {
  control: () => ({
    width: '175px',
    height: '34px',
    opacity: 0.6,
    borderRadius: '2px',
    boxShadow: '2px 2px 5px 0 rgba(0, 0, 0, 0.06)',
    border: 'solid 1px #dbdbdb',
    backgroundColor: '#ffffff',
    cursor: 'pointer'
  }),

  indicatorSeparator: () => ({
    display: 'none'
  }),

  dropdownIndicator: () => ({
    position: 'absolute',
    top: '4px',
    right: '20px',
    width: '6px',
    color: '#8da6be'
  }),

  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused || state.isSelected ? '#f1f4f7' : 'white',
    fontSize: '14px',
    textAlign: 'left',
    color: '#1c2243',
    cursor: state.isSelected ? 'default' : 'pointer',
    paddingLeft: '22px',
    height: '36px',
    fontWeight: 300
  }),

  menu: () => ({
    position: 'absolute',
    zIndex: 5000,
    border: 'none',
    borderRadius: '3px',
    backgroundColor: '#fff',
    boxShadow: '2px 2px 30px 0 #d5dee6',
    minWidth: '150px',
    width: '100%'
  }),

  valueContainer: () => ({
    width: '96px',
    height: '26px',
    fontSize: '12px',
    fontWeight: 300,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#1c2243',
    marginLeft: '13px'
  }),

  menuList: () => ({
    maxHeight: '250px',
    overflowX: 'auto',
    borderRadius: '3px',
    paddingTop: '17px',
    paddingBottom: '13px'
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return {
      ...provided, opacity, transition, color: '#1c2243', width: 'fit-content', fontSize: '12px', fontWeight: 300, left: '16px'
    };
  }
};

export const statusSelect = {
  control: () => ({
    height: '34px',
    border: 'solid 1px #dbdbdb',
    boxShadow: '2px 2px 5px 0 rgba(0, 0, 0, 0.06), inset 0 -2px 0 0 #696969',
    backgroundColor: '#ffffff',
    opacity: '0.6',
    left: '16px',
    fontSize: '12.5px',
    fontWeight: 500,
    color: '#082731',
    width: '155px'
  }),

  indicatorSeparator: () => ({
    display: 'none'
  }),

  dropdownIndicator: (provided, state) => {
    const children = state.selectProps.value.label;
    const color = () => {
      if (children === 'Re-opened') return '#e26464';
      if (children === 'Risk-accepted') return colors.apple;
      if (children === 'Open') return colors.warmPurple;
      return '#90a9c0';
    };

    return {
      position: 'absolute',
      top: '7px',
      right: '6px',
      color: color()
    };
  },

  option: (provided, state) => {
    const { children } = state;
    const color = () => {
      if (children === 'Re-Opened') return '#e26464';
      if (children === 'Risk-Accepted') return colors.apple;
      if (children === 'Open') return colors.warmPurple;
      return '#90a9c0';
    };

    return {
      ...provided,
      fontSize: '14px',
      textAlign: 'left',
      color: color(),
      cursor: state.isSelected ? 'default' : 'pointer',
      paddingLeft: '22px',
      height: '36px',
      fontWeight: state.selectProps.value.value === state.value ? 900 : 400,
      backgroundColor: state.isFocused /* || state.isSelected */ ? '#f1f4f7' : 'white'
    };
  },

  menu: () => ({
    position: 'absolute',
    zIndex: 5000,
    border: 'none',
    borderRadius: '3px',
    backgroundColor: '#fff',
    boxShadow: '2px 2px 30px 0 #d5dee6',
    minWidth: '150px',
    width: '100%'
  }),

  valueContainer: () => ({
    top: '4px'
  }),

  menuList: () => ({
    maxHeight: '250px',
    overflowX: 'auto',
    borderRadius: '3px',
    paddingTop: '17px',
    paddingBottom: '13px'
  }),

  singleValue: (provided, state) => {
    const { children } = state;
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    const color = () => {
      if (children === 'Re-opened') return '#e26464';
      if (children === 'Risk-accepted') return colors.apple;
      if (children === 'Open') return colors.warmPurple;
      return '#90a9c0';
    };
    return {
      ...provided, opacity, transition, color: color(), width: 'fit-content', fontSize: '13.5px', fontWeight: 600, left: '16px'
    };
  }
};

export const standardDropdownStyle = {
  control: () => ({
    height: '35px',
    width: 'auto',
    borderRadius: '1px',
    boxShadow: '2px 2px 5px 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 #afafaf',
    backgroundColor: '#ffffff',
    border: 'solid 1px #fafbfc',
    borderBottomWidth: '0px',
    borderRightWidth: '0px',
    color: 'white',
    paddingLeft: '10px',
    fontWeight: '700',
    textOverflow: 'ellipsis'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  placeholder: () => ({
    fontSize: '13px',
    color: '#1c2243',
    position: 'relative',
    fontWeight: 400
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    position: 'absolute',
    top: '0px',
    right: '4px',
    color: '#505965',
    width: '30px'
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#edf2f7' : 'white',
    fontSize: '14px',
    textAlign: 'left',
    color: '#1c2243',
    cursor: state.isSelected ? 'default' : 'pointer',
    paddingLeft: '22px',
    paddingRight: '22px',
    height: '36px',
    textTransform: 'none',
    textOverflow: 'ellipsis',
    fontWeight: state.isSelected ? 600 : 300,
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }),
  valueContainer: () => ({
    height: '33px',
    display: 'flex',
    alignItems: 'center'
  }),
  menu: () => ({
    position: 'absolute',
    marginTop: '6px',
    zIndex: 5000,
    width: '100%',
    border: 'none',
    borderRadius: '3px',
    backgroundColor: '#fff',
    boxShadow: '2px 2px 30px 0 #d5dee6',
    minWidth: '190px'
  }),
  menuList: () => ({
    maxHeight: '110px',
    overflowX: 'auto',
    borderRadius: '3px',
    paddingTop: '17px',
    paddingBottom: '13px'
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return {
      ...provided, opacity, transition, color: '#1c2243', width: 'fit-content', height: '19px', fontSize: '14px', fontWeight: 400, top: '8px', position: 'relative', maxWidth: 'calc(100% - 24px)'
    };
  }
};

export const standardDropdownStyleUnCapitalize = {
  ...standardDropdownStyle,
  control: () => ({
    height: '35px',
    width: '200px',
    borderRadius: '1px',
    boxShadow: '2px 2px 5px 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 #afafaf',
    backgroundColor: '#ffffff',
    border: 'solid 1px #fafbfc',
    borderBottomWidth: '0px',
    borderRightWidth: '0px',
    color: 'white',
    paddingLeft: '10px',
    fontWeight: '700',
    textOverflow: 'ellipsis'
  })
};

function getUserRoleColor (value) {
  switch (value) {
    case 'asset owner':
      return '#53a9ff';
    case 'client':
      return '#0082ed';
    case 'pentester':
      return '#ea3158';
    case 'admin':
      return '#a4247a';
    default:
      return '#63758d';
  }
}

export const UserRoleDropdownStyle = {
  container: (provided, state) => ({
    height: '34px',
    width: '143px',
    cursor: state.isDisabled ? 'not-allowed' : 'pointer'
  }),

  control: (provided, state) => ({
    border: 'none',
    display: 'flex',
    fontSize: '13.5px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#1c2243',
    // padding: '1px 8px',
    boxShadow: state.menuIsOpen ? '0 2px 50px 0 rgba(0, 0, 0, 0.03)' : '',
    height: '34px',
    width: '143px'
  }),

  placeholder: () => ({
    color: '#fff',
    top: '7px',
    textAlign: 'left',
    position: 'relative',
    marginLeft: '12px'
  }),

  indicatorSeparator: () => ({
    display: 'none'
  }),

  indicatorsContainer: () => ({
    bottom: '3px',
    color: '#fff!important',
    position: 'relative'
  }),

  dropdownIndicator: (provided) => ({
    ...provided,
    position: 'absolute',
    top: '2px',
    right: '2px',
    color: '#ffffff',
    width: '30px'
  }),

  valueContainer: (provided, state) => ({
    height: '34px',
    width: '143px',
    fontSize: '13.5px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    textAlign: 'center',
    color: '#fff',
    position: 'relative',

    borderRadius: '2px',
    boxShadow: `2px 2px 5px 0 rgba(0, 0, 0, 0.06), inset 0 -2px 0 0 ${state.getValue()[0] === undefined ? '#63758d' : getUserRoleColor(state.getValue()[0].value)}`,
    border: 'solid 1px',
    borderColor: state.getValue()[0] === undefined ? '#63758d' : getUserRoleColor(state.getValue()[0].value),
    backgroundColor: state.getValue()[0] === undefined ? '#63758d' : getUserRoleColor(state.getValue()[0].value)
  }),

  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#f5f7f9' : 'white',
    fontSize: '13.5px',
    textAlign: 'left',
    color: '#1c2243',
    cursor: 'pointer',
    paddingLeft: '22px',
    height: '40px'
  }),

  menu: () => ({
    position: 'absolute',
    zIndex: 5000,
    width: '143px',
    maxWidth: '143px',
    height: '191px',
    border: 'none',
    borderRadius: '3px',
    backgroundColor: '#fff',
    boxShadow: '0 2px 50px 0 #d8dfe5'
  }),

  menuList: () => ({
    maxHeight: '143px',
    height: '191px',
    borderRadius: '3px',
    paddingTop: '15px',
    paddingBottom: '12px'
  }),

  singleValue: (provided, state) => ({
    marginTop: '-6px',
    opacity: state.isDisabled ? 0.5 : 1,
    transition: 'opacity 300ms',
    position: 'absolute',
    bottom: '7px',
    left: '12px'
  })
};

export const groupSelectContext = {
  control: (provided, state) => {
    const children = state.selectProps.value[0].label;
    const width = () => {
      if (children === 'None') return '140px';
      return '175px';
    };
    return {
      width: width(),
      height: '34px',
      opacity: 0.6,
      borderRadius: '2px',
      boxShadow: '2px 2px 5px 0 rgba(0, 0, 0, 0.06)',
      border: 'solid 1px #dbdbdb',
      backgroundColor: '#ffffff',
      cursor: 'pointer'
    };
  },

  indicatorSeparator: () => ({
    display: 'none'
  }),

  dropdownIndicator: () => ({
    position: 'absolute',
    top: '4px',
    right: '20px',
    width: '6px',
    color: '#8da6be'
  }),

  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused || state.isSelected ? '#f1f4f7' : 'white',
    fontSize: '14px',
    textAlign: 'left',
    color: '#1c2243',
    cursor: state.isSelected ? 'default' : 'pointer',
    paddingLeft: '22px',
    height: '36px',
    fontWeight: 300
  }),

  menu: () => ({
    position: 'absolute',
    zIndex: 5000,
    border: 'none',
    borderRadius: '3px',
    backgroundColor: '#fff',
    boxShadow: '2px 2px 30px 0 #d5dee6',
    minWidth: '150px',
    width: '100%'
  }),

  valueContainer: () => ({
    width: '96px',
    height: '26px',
    fontSize: '12px',
    fontWeight: 300,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#1c2243',
    marginLeft: '13px'
  }),

  menuList: () => ({
    maxHeight: '250px',
    overflowX: 'auto',
    borderRadius: '3px',
    paddingTop: '17px',
    paddingBottom: '13px'
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return {
      ...provided, opacity, transition, color: '#1c2243', width: 'fit-content', fontSize: '12px', fontWeight: 300, left: '16px'
    };
  }
};

export const InputFilterGroupStyle = {
  container: () => ({
    'width': '100%',
    'height': '34px',
    'borderRadius': '1px',
    'boxShadow': 'inset 0 -1px 0 0 #afafaf',
    'backgroundColor': '#ffffff',
    'borderLeft': `solid 12px ${colors.softBlue}`,
    'borderTop': '1px solid transparent',
    'borderBottomWidth': '0px',
    'borderRight': '1px solid transparent',
    'position': 'relative',
    '&:hover': {
      borderStyle: 'solid',
      borderColor: `${colors.grey17}`,
      borderWidth: '1px 1px 0px 12px',
      borderLeftColor: `${colors.softBlue}`
    }

  }),
  control: () => ({
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '34px',
    color: 'white',
    padding: '0 2px 0 10px',
    fontWeight: '700',
    textOverflow: 'ellipsis'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  placeholder: () => ({
    fontSize: '13px',
    color: '#afb1bc',
    position: 'relative',
    fontWeight: 300,
    height: '34px',
    lineHeight: '2.7'
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#505965',
    width: '32px'
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? `${colors.iceBlue}` : 'white',
    fontSize: '14px',
    textAlign: 'left',
    color: '#1c2243',
    cursor: state.isSelected ? 'default' : 'pointer',
    paddingLeft: '22px',
    paddingRight: '22px',
    height: '36px',
    textTransform: 'none',
    textOverflow: 'ellipsis',
    fontWeight: state.isFocused ? 600 : 300,
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }),
  valueContainer: () => ({
    width: '100%',
    position: 'relative'
  }),
  input: () => ({
    'width': '100%',
    'position': 'absolute',
    'top': '0',
    'color': '#1c2243',
    'lineHeight': '2.3',
    '&:focus': {
      backgroundColor: '#ffffff'
    }
  }),
  menu: () => ({
    position: 'absolute',
    marginTop: '0',
    left: '-12px',
    top: '33px',
    zIndex: 5000,
    border: 'none',
    borderRadius: '3px',
    backgroundColor: '#fff',
    boxShadow: '2px 2px 30px 0 #d5dee6',
    minWidth: '190px'
  }),
  menuList: () => ({
    overflowX: 'auto',
    borderRadius: '3px',
    paddingTop: '17px',
    paddingBottom: '13px'
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return {
      ...provided, opacity, transition, color: '#1c2243', width: 'fit-content', height: '19px', fontSize: '14px', fontWeight: 400, top: '8px', position: 'relative', maxWidth: 'calc(100% - 24px)'
    };
  }
};

export const newDropdownStyle = {
  control: () => ({
    height: '35px',
    width: '154px',
    borderRadius: '1px',
    boxShadow: '2px 2px 5px 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 #afafaf',
    backgroundColor: '#ffffff',
    border: 'solid 1px #fafbfc',
    borderBottomWidth: '0px',
    borderRightWidth: '0px',
    color: 'white',
    paddingLeft: '10px',
    fontWeight: '700',
    textOverflow: 'ellipsis'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  placeholder: () => ({
    fontSize: '13px',
    color: '#1c2243',
    position: 'relative',
    fontWeight: 400
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    position: 'absolute',
    top: '0px',
    left: '122px',
    color: '#505965',
    width: '30px'
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: 'white',
    fontSize: '14px',
    textAlign: 'left',
    color: '#1c2243',
    cursor: state.isSelected ? 'default' : 'pointer',
    paddingLeft: '22px',
    paddingRight: '22px',
    height: '36px',
    textTransform: 'none',
    width: '290px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontWeight: state.isSelected ? 600 : 300
  }),
  valueContainer: () => ({
    height: '33px',
    display: 'flex',
    alignItems: 'center'
  }),
  menu: () => ({
    marginTop: '6px',
    zIndex: 5000,
    width: '290px',
    border: 'none',
    borderRadius: '3px',
    backgroundColor: '#fff',
    boxShadow: '2px 2px 30px 0 #d5dee6'
  }),
  menuList: () => ({
    width: '290px',
    maxHeight: '100px',
    overflowX: 'auto',
    borderRadius: '3px',
    paddingTop: '17px',
    paddingBottom: '13px'
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return {
      ...provided, opacity, transition, color: '#1c2243', width: 'fit-content', height: '19px', fontSize: '14px', fontWeight: 400, top: '8px', position: 'relative', maxWidth: 'calc(100% - 24px)'
    };
  }
};
