import fetchApi from 'services/api/connector';

export const getAllServices = (ws) => fetchApi(`ws/${ws}/services`);

export const getServices = (ws, queryParam) => fetchApi(`ws/${ws}/services?${queryParam}`);

export const createService = (ws, data) => fetchApi(`ws/${ws}/services`, { method: 'POST', data });

export const fetchById = (ws, id) => fetchApi(`ws/${ws}/services/${id}`);

export const deleteService = (ws, ids) => fetchApi(`ws/${ws}/services`, { method: 'DELETE', data: { ids }, version: 'v3' });

export const updateService = (ws, data) => fetchApi(`ws/${ws}/services/${data.id}`, { method: 'PATCH', data });

export const deleteAllServices = (ws, queryParam) => fetchApi(`ws/${ws}/services?q=${encodeURI(JSON.stringify(queryParam))}`, { method: 'DELETE', version: 'v3' });

export const setServicesTags = (ws, data) => fetchApi(`ws/${ws}/services/set_tags`, { method: 'POST', data, version: 'v3' });

export const setAllServicesTags = (ws, queryParam, data) => fetchApi(`ws/${ws}/services/set_tags?q=${encodeURI(JSON.stringify(queryParam))}`, { method: 'POST', data, version: 'v3' });
