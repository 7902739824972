export const GET_DATA_VULNS_SUCCESS = 'GET_DATA_VULNS_SUCCESS';
export const GET_DATA_VULNS_FAIL = 'GET_DATA_VULNS_FAIL';
export const GET_DATA_VULNS_START = 'GET_DATA_VULNS_START';
export const GET_DATA_SERVICES_SUCCESS = 'GET_DATA_SERVICES_SUCCESS';
export const GET_DATA_SERVICES_FAIL = 'GET_DATA_SERVICES_FAIL';
export const GET_DATA_SERVICES_START = 'GET_DATA_SERVICES_START';
export const GET_DATA_TOOLS_SUCCESS = 'GET_DATA_TOOLS_SUCCESS';
export const GET_DATA_TOOLS_FAIL = 'GET_DATA_TOOLS_FAIL';
export const GET_DATA_TOOLS_START = 'GET_DATA_TOOLS_START';
export const SHOW_HOST_DETAIL = 'SHOW_HOST_DETAIL';
export const HIDE_HOST_DETAIL = 'HIDE_HOST_DETAIL';
export const RESET_STATE_HOST_DETAIL = 'RESET_STATE_HOST_DETAIL';
export const ADD_SERVICE_ON_ASSET_FAIL = 'ADD_SERVICE_ON_ASSET_FAIL';
export const ADD_SERVICE_ON_ASSET_SUCCESS = 'ADD_SERVICE_ON_ASSET_SUCCESS';
export const HOST_ADD_EDIT_SERVICE_SUCCESS = 'HOST_ADD_EDIT_SERVICE_SUCCESS';
export const SELECT_SERVICE = 'SELECT_SERVICE';
export const UPDATE_VULN_ASSET_PREVIEW_SUCCESS = 'UPDATE_VULN_ASSET_PREVIEW_SUCCESS';
export const UPDATE_VULN_ASSET_PREVIEW_FAIL = 'UPDATE_VULN_ASSET_PREVIEW_FAIL';
export const ASSET_DETAIL_SERVICE_SELECTED = 'ASSET_DETAIL_SERVICE_SELECTED';
export const ASSET_DETAIL_SERVICE_UNSELECTED = 'ASSET_DETAIL_SERVICE_UNSELECTED';
export const ASSET_DETAIL_NEW_PIVOT = 'ASSET_DETAIL_NEW_PIVOT';
export const SELECT_ALL_ITEMS = 'SELECT_ALL_ITEMS';
export const UNSELECT_ALL_ITEMS = 'UNSELECT_ALL_ITEMS';
export const SHOW_DETAIL = 'SHOW_DETAIL';
export const HIDE_DETAIL = 'HIDE_DETAIL';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const SET_PAGE_NUMBER_SERVICES = 'SET_PAGE_NUMBER_SERVICES';
export const SHOW_MODAL_DELETE = 'SHOW_MODAL_DELETE';
export const HIDE_MODAL_DELETE = 'HIDE_MODAL_DELETE';
export const DELETE_START = 'DELETE_START';
export const DELETE_SUCCESS = 'DELETE_SUCCESS';
export const DELETE_FAIL = 'DELETE_FAIL';
export const RESET_SERVICE_DETAIL = 'RESET_SERVICE_DETAIL';
export const ASSET_DETAIL_UPDATE_SERVICE_SUCCESS = 'ASSET_DETAIL_UPDATE_SERVICE_SUCCESS';
export const RESET_ASSET_STATE = 'RESET_ASSET_STATE';
export const SET_VULNS_TAB = 'SET_VULNS_TAB';
export const ASSET_DETAIL_VULN_SELECTED = 'ASSET_DETAIL_VULN_SELECTED';
export const VULN_UNSELECTED = 'VULN_UNSELECTED';
export const SHOW_VULNS_SIDEBAR_FILTERS = 'SHOW_VULNS_SIDEBAR_FILTERS';
export const SET_HOST_FOR_WORKING = 'SET_HOST_FOR_WORKING';
export const CREATE_VULN_FROM_ASSET = 'CREATE_VULN_FROM_ASSET';
export const VULN_ASSET_ERROR = 'VULN_ASSET_ERROR';
export const VULN_ASSET_SET_BULK_UPDATE_FIELD = 'VULN_ASSET_SET_BULK_UPDATE_FIELD';
export const VULN_ASSET_SET_BULK_UPDATE_VALUE = 'VULN_ASSET_SET_BULK_UPDATE_VALUE';
export const VULN_ASSET_ADD_BULK_UPDATE_VALUE = 'VULN_ASSET_ADD_BULK_UPDATE_VALUE';
export const VULN_ASSET_REMOVE_BULK_UPDATE_VALUE = 'VULN_ASSET_REMOVE_BULK_UPDATE_VALUE';
export const VULN_ASSET_BULK_UPDATE_FINISHED = 'VULN_ASSET_BULK_UPDATE_FINISHED';
export const VULNS_CONFIRMATION_CHANGE_START = 'VULNS_CONFIRMATION_CHANGE_START';
export const VULNS_ASSET_REFRESH_VULNS = 'VULNS_ASSET_REFRESH_VULNS';
export const VULN_ASSET_SET_BULK_UPDATE_SUCCESS = 'VULN_ASSET_SET_BULK_UPDATE_SUCCESS';
export const SELECT_TOTAL_ITEMS = 'SELECT_TOTAL_ITEMS';
export const UNSELECT_TOTAL_ITEMS = 'UNSELECT_TOTAL_ITEMS';
export const UNSELECTED_ROW_ITEM_FROM_SELECT_ALL = 'UNSELECTED_ROW_ITEM_FROM_SELECT_ALL';
export const AUTOSELECT_VULN_ITEM = 'AUTOSELECT_VULN_ITEM';
export const VULNS_ASSET_SHOW_CONTEXT_MENU = 'VULNS_ASSET_SHOW_CONTEXT_MENU';
export const SET_TAGS_SUCCESS = 'SET_TAGS_SUCCESS';
export const SET_TAGS_FAIL = 'SET_TAGS_FAIL';
export const TAGS_UPDATE_DETAIL = 'TAGS_UPDATE_DETAIL';
export const DUPLICATES_GET_SUCCESS = 'DUPLICATES_GET_SUCCESS';
export const DUPLICATES_MARK_AS_MAIN_SUCCESS = 'DUPLICATES_MARK_AS_MAIN_SUCCESS';
export const DUPLICATES_MARK_SUCCESS = 'DUPLICATES_MARK_SUCCESS';
export const DUPLICATES_REMOVE_SUCCESS = 'DUPLICATES_REMOVE_SUCCESS';
export const DUPLICATES_ERROR = 'DUPLICATES_ERROR';
export const VULNS_ASSET_REFRESH_VULN_ENRICHMENT_REQUEST = 'VULNS_ASSET_REFRESH_VULN_ENRICHMENT_REQUEST';
export const VULNS_ASSET_REFRESH_VULN_ENRICHMENT_SUCCESS = 'VULNS_ASSET_REFRESH_VULN_ENRICHMENT_SUCCESS';
export const VULNS_ASSET_REFRESH_VULN_ENRICHMENT_FAILURE = 'VULNS_ASSET_REFRESH_VULN_ENRICHMENT_FAILURE';
export const VULNS_ASSET_UPDATE_VULN_CLIENT_SIDE = 'VULNS_ASSET_UPDATE_VULN_CLIENT_SIDE';
