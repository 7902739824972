import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { selectMaxJobsLimit } from 'store/Config/selector';
import { selectModel } from 'store/Filters/selectors';

export const selectJobs = (state) => get(state, 'jobs.list', []);
export const selectJobsIsEmpty = (state) => isEmpty(selectJobs(state));
export const selectJobSelected = (state) => get(state, 'jobs.jobSelected', {});
export const selectSearchQuery = (state) => get(state, 'jobs.searchQuery', '');
export const selectJobsFiltered = (state) => {
  const jobs = selectJobs(state);
  const searchQuery = selectSearchQuery(state);
  if (!searchQuery) return jobs;
  return jobs.filter((job) => (job.name && job.name.indexOf(searchQuery) > -1));
};

// Actions
export const selectActions = () => [
  {
    desc: 'Append',
    name: 'append',
    id: 'append'
  },
  {
    desc: 'Delete', // all objects
    name: 'Delete', // all objects
    id: 'delete'
  },
  {
    desc: 'Update', // an Attribute
    name: 'Update', // an Attribute
    id: 'update'
  }
];

export const selectFields = (state, model) => get(state, `jobs.fields.${model === 'host' ? 'asset' : model}`, {});
export const selectField = (state, model, field) => get(state, `jobs.fields.${model === 'host' ? 'asset' : model}.${field}`, null);
export const selectModels = (state) => get(state, 'jobs.models', []);

export const selectJobId = (state) => get(state, 'jobs.jobSelected.id', 0);
export const selectJobName = (state) => get(state, 'jobs.jobSelected.name', null);
export const selectJobDescription = (state) => get(state, 'jobs.jobSelected.description', null);
export const selectJobModel = (state) => {
  const model = get(state, 'jobs.jobSelected.model', null);
  return model === 'host' ? 'asset' : model;
};
export const selectJobRules = (state) => get(state, 'jobs.jobSelected.rules', []);
export const selectJobTasks = (state) => get(state, 'jobs.jobSelected.tasks', []);
export const selectJobTaskIds = (state) => selectJobTasks(state).filter((task) => task.id > 0).map((task) => task.id);
export const selectJobPipelines = (state) => get(state, 'jobs.jobSelected.pipelines', []);
export const selectTotalJobs = (state) => {
  const jobs = selectJobs(state);
  return jobs.length;
};
export const selectLimitReached = (state) => {
  const total = selectTotalJobs(state);
  const limit = selectMaxJobsLimit(state);
  if (!limit) return false;
  return (total >= limit);
};

export const selectError = (state) => get(state, 'jobs.error', false);
export const selectErrorMessage = (state) => get(state, 'jobs.errorMessage', '');

export const selectDisplayName = (state, field) => {
  const model = get(state, 'jobs.jobSelected.model', null);
  const fields = selectModel(state, model);
  const newField = fields.find((f) => f.name === field);
  return get(newField, 'display_name', '');
};
