import * as types from './types';
import initialState from './initialState';
import uniq from 'lodash/uniq';
import { copy } from '../../Common/Functions/Copy';

const manageGeneral = (state = initialState, action) => {
  switch (action.type) {
    case types.GENERAL_MANAGE_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.errorMessage,
        isFetchingExpandedData: false
      };
    }

    case types.GENERAL_MANAGE_GET_REQUEST: {
      return {
        ...state,
        isFetching: true
      };
    }

    case types.GENERAL_MANAGE_GET_SUCCESS: {
      return {
        ...state,
        list: action.data,
        count: action.count,
        isFetching: false
      };
    }

    case types.GENERAL_MANAGE_CLEAR_ERROR: {
      return {
        ...state,
        error: false,
        errorMessage: ''
      };
    }

    case types.GENERAL_MANAGE_SELECTED: {
      return {
        ...state,
        vulnsSelected: uniq([...state.vulnsSelected, ...action.payload]),
        vulnSelectedForWorking: uniq([...state.vulnsSelected, ...action.payload])
      };
    }

    case types.GENERAL_MANAGE_NEW_PIVOT: {
      return {
        ...state,
        lastSelected: action.payload
      };
    }

    case types.GENERAL_MANAGE_UNSELECTED: {
      const filterByID = (array, id) => array.filter((el) => el._id !== id);
      const newVulnsSelected = action.payload.reduce((vulnsSelected, vulnToDelete) => filterByID(vulnsSelected, vulnToDelete._id), state.vulnsSelected);

      return {
        ...state,
        vulnsSelected: newVulnsSelected
      };
    }

    case types.GENERAL_MANAGE_SELECT_ALL: {
      return {
        ...state,
        vulnsSelected: [...action.vulnsList]
      };
    }

    case types.GENERAL_MANAGE_UNSELECT_ALL: {
      return {
        ...state,
        vulnsSelected: []
      };
    }

    case types.GENERAL_MANAGE_SHOW_CONTEXT_MENU: {
      return {
        ...state,
        showContextMenu: action.show,
        contextMenuXPos: action.contextMenuXPos,
        contextMenuYPos: action.contextMenuYPos
      };
    }

    case types.GENERAL_MANAGE_GET_GROUPED_DATA_START: {
      return {
        ...state,
        isFetchingExpandedData: true
      };
    }
    case types.GENERAL_MANAGE_GET_GROUPED_DATA_SUCCESS: {
      const list = state.list;
      list[action.index].groupData = action.data;

      return {
        ...state,
        list,
        isFetchingExpandedData: false
      };
    }

    case types.GENERAL_MANAGE_SEARCH_VULNS_FAIL: {
      return {
        ...state,
        isFetching: false,
        isFetchingExpandedData: false,
        error: true,
        errorMessage: action.data
      };
    }

    case types.GENERAL_MANAGE_SET_BULK_UPDATE_FIELD: {
      return {
        ...state,
        bulkUpdateField: action.field,
        bulkUpdateValue: ''
      };
    }

    case types.GENERAL_MANAGE_SET_BULK_UPDATE_VALUE: {
      return {
        ...state,
        bulkUpdateValue: action.value
      };
    }

    case types.GENERAL_MANAGE_ADD_BULK_UPDATE_VALUE: {
      const bulkUpdateValue = state.bulkUpdateValue;
      bulkUpdateValue.push(action.value);

      return {
        ...state,
        bulkUpdateValue
      };
    }

    case types.GENERAL_MANAGE_REMOVE_BULK_UPDATE_VALUE: {
      let bulkUpdateValue = state.bulkUpdateValue;
      bulkUpdateValue = bulkUpdateValue.filter((element) => element !== action.value);

      return {
        ...state,
        bulkUpdateValue
      };
    }

    case types.GENERAL_MANAGE_BULK_UPDATE_FINISHED: {
      return {
        ...state,
        isFetching: false,
        isFetchingExpandedData: false,
        bulkUpdateField: '',
        bulkUpdateValue: ''
      };
    }

    case types.GENERAL_MANAGE_SHOW_MODAL_DELETE_CONFIRMATION: {
      return {
        ...state,
        showDeleteConfirmation: true
      };
    }

    case types.GENERAL_MANAGE_HIDE_MODAL_DELETE_CONFIRMATION: {
      return {
        ...state,
        showDeleteConfirmation: false,
        isFetching: false,
        isFetchingExpandedData: false
      };
    }

    case types.GENERAL_MANAGE_DELETE: {
      return {
        ...state,
        isFetching: true,
        vulnsSelected: initialState.vulnsSelected,
        lastSelected: initialState.lastSelected,
        lastIndexForRange: initialState.lastIndexForRange
      };
    }

    case types.GENERAL_MANAGE_HIDE_MODAL_DELETE_CONFIRMATION_SUCCEED: {
      return {
        ...state,
        showDeleteConfirmation: false
      };
    }

    case types.GENERAL_MANAGE_UPDATE_VULNS_LIST: {
      return {
        ...state,
        list: action.vulnsList
      };
    }

    case types.GENERAL_MANAGE_RESET_STATE: {
      return {
        ...initialState,
        vulnSelectedForWorking: state.vulnSelectedForWorking
      };
    }

    case types.GENERAL_MANAGE_CONFIRMATION_CHANGE_START: {
      return {
        ...state,
        isFetching: true,
        isFetchingExpandedData: true,
        error: false,
        errorMessage: ''
      };
    }

    case types.GENERAL_MANAGE_SET_BULK_UPDATE_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        isFetchingExpandedData: false,
        error: false,
        errorMessage: ''
      };
    }

    case types.GENERAL_MANAGE_CONFIRMATION_CHANGE_SUCCESS: {
      const list = state.list;

      if (action.isGroupingBy) {
        const vulnGroup = list && list.find((g) => g.groupData && g.groupData.find((el) => el._id === action.vuln._id));
        const vulnGroupIndex = list && list.findIndex((g) => g.groupData && g.groupData.find((el) => el._id === action.vuln._id));
        const vulnSelected = vulnGroup.groupData && vulnGroup.groupData.findIndex((el) => el._id === action.vuln._id);
        list[vulnGroupIndex].groupData[vulnSelected] = copy(action.vuln);
      } else {
        const index = list.findIndex((x) => action.vuln._id === x._id);
        list[index] = copy(action.vuln);
      }

      const vulnDetail = state.vulnDetail ? copy(state.vulnDetail) : state.vulnDetail;

      if (state.vulnDetail && vulnDetail._id === action.vuln._id) vulnDetail.confirmed = action.vuln.confirmed;

      return {
        ...state,
        isFetching: false,
        isFetchingExpandedData: false,
        list,
        vulnDetail
      };
    }

    case types.GENERAL_MANAGE_CONFIRMATION_CHANGE_FAIL: {
      return {
        ...state,
        isFetching: false,
        isFetchingExpandedData: false,
        error: true,
        errorMessage: action.error
      };
    }

    case types.GENERAL_MANAGE_REFRESH_VULNS: {
      return {
        ...state,
        list: action.vulnsList,
        count: action.vulnsCount,
        vulnsSelected: action.vulnsSelected,
        vulnDetail: action.vulnDetail
      };
    }

    case types.GENERAL_MANAGE_SELECT_TOTAL_VULNS: {
      return {
        ...state,
        selectAll: true
      };
    }

    case types.GENERAL_MANAGE_UNSELECT_TOTAL_VULNS: {
      return {
        ...state,
        selectAll: false
      };
    }

    case types.GENERAL_MANAGE_UPDATE_PREVIEW_SUCCESS: {
      const vulnsSelectedArray = state.vulnsSelected;
      const vulnUpdatedId = vulnsSelectedArray.findIndex((vuln) => vuln._id === action.vuln._id);
      vulnsSelectedArray.splice(vulnUpdatedId, 1, action.vuln);
      return {
        ...state,
        isFetchingPreview: false,
        vuln: action.vuln,
        vulnDetail: { ...action.vuln },
        list: action.vulnsList,
        vulnsSelected: vulnsSelectedArray
      };
    }

    case types.GENERAL_MANAGE_UPDATE_PREVIEW_FAILURE: {
      return {
        ...state,
        error: true,
        errorMessage: action.error
      };
    }

    case types.GENERAL_MANAGE_SHOW_VULN_DETAIL: {
      return {
        ...state,
        vulnDetail: action.vuln,
        vulnDetailSelectedTab: action.vulnDetailSelectedTab || state.vulnDetailSelectedTab,
        showVulnDetail: true
      };
    }

    case types.GENERAL_MANAGE_HIDE_VULN_DETAIL: {
      return {
        ...state,
        vulnDetail: undefined,
        showVulnDetail: false,
        vulnDetailSelectedTab: initialState.vulnDetailSelectedTab
      };
    }

    case types.GENERAL_MANAGE_SET_VULN_DETAIL_TAB: {
      return {
        ...state,
        vulnDetailSelectedTab: action.vulnDetailSelectedTab
      };
    }

    case types.GENERAL_MANAGE_SHOW_FILTERS: {
      return {
        ...state,
        showRightFilters: action.visible
      };
    }

    case types.GENERAL_MANAGE_CREATE_UPDATE_SUCCESS: {
      const vulnsList = state.list;

      return {
        ...state,
        list: [...action.vulns, ...vulnsList]
      };
    }

    case types.ADD_EVIDENCE_FAILURE: {
      return {
        ...state,
        error: true,
        errorMessage: action.error
      };
    }

    case types.GENERAL_MANAGE_SET_TAGS_REQUEST: {
      return {
        ...state,
        isFetching: true
      };
    }

    case types.GENERAL_MANAGE_SET_TAGS_SUCCESS: {
      return {
        ...state,
        isFetching: false
      };
    }

    case types.GENERAL_MANAGE_SET_TAGS_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.error
      };
    }

    case types.GENERAL_MANAGE_TAGS_UPDATE_DETAIL: {
      return {
        ...state,
        isFetchingPreview: false,
        isFetching: false,
        vulnDetail: { ...action.vuln }
      };
    }

    case types.CONTEXT_REFRESH_VULN_ENRICHMENT_REQUEST: {
      return {
        ...state,
        isFetchingVulnEnrichment: true
      };
    }
    case types.CONTEXT_REFRESH_VULN_ENRICHMENT_SUCCESS: {
      return {
        ...state,
        isFetchingVulnEnrichment: false
      };
    }
    case types.CONTEXT_REFRESH_VULN_ENRICHMENT_FAILURE: {
      return {
        ...state,
        isFetchingVulnEnrichment: false,
        bulkUpdateValue: '',
        error: true,
        errorMessage: action.errorMessage
      };
    }
    case types.GENERAL_MANAGE_DUPLICATES_GET_SUCCESS: {
      return {
        ...state,
        error: false,
        vulnDetailDuplicates: action.duplicates
      };
    }
    case types.GENERAL_MANAGE_DUPLICATES_MARK_AS_MAIN_SUCCESS: {
      return {
        ...state,
        error: false
      };
    }
    case types.GENERAL_MANAGE_DUPLICATES_MARK_SUCCESS: {
      return {
        ...state,
        error: false,
        vulnsList: action.updatedVulnList
      };
    }
    case types.GENERAL_MANAGE_DUPLICATES_REMOVE_SUCCESS: {
      return {
        ...state,
        error: false
      };
    }
    case types.GENERAL_MANAGE_DUPLICATES_ERROR: {
      return {
        ...state,
        error: true,
        errorMessage: action.error
      };
    }
    case types.GENERAL_MANAGE_SET_SELECTED_COMMENT: {
      return {
        ...state,
        selectedComment: action.selectedComment
      };
    }
    case types.GENERAL_MANAGE_GET_COMMENTS_REQUEST:
      return { ...state, comment: undefined };
    case types.GENERAL_MANAGE_GET_COMMENTS_SUCCESS:
      return {
        ...state, isFetching: false, error: false, errorMessage: '', comments: action.data
      };
    case types.GENERAL_MANAGE_GET_COMMENTS_FAIL:
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.data
      };

    case types.GENERAL_MANAGE_ADD_COMMENT_REQUEST:
      return { ...state, comment: undefined };
    case types.GENERAL_MANAGE_ADD_COMMENT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        errorMessage: '',
        comments: [
          ...(state.comments.filter((item) => (item.id !== action.data.id))),
          action.data
        ]
      };
    case types.GENERAL_MANAGE_ADD_COMMENT_FAIL:
      return {
        ...state,
        isFetching: false,
        comment: undefined,
        error: true,
        errorMessage: action.data
      };

    case types.GENERAL_MANAGE_REMOVE_COMMENT_REQUEST:
      return { ...state };
    case types.GENERAL_MANAGE_REMOVE_COMMENT_SUCCESS:
      return {
        ...state, isFetching: false, error: false, errorMessage: '', comments: [...(state.comments.filter((item) => (item.id !== action.commentId)))]
      };

    case types.GENERAL_MANAGE_REMOVE_COMMENT_FAIL:
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.data
      };
    case types.GENERAL_MANAGE_UPDATE_COMMENT_REQUEST: {
      return {
        ...state
      };
    }

    case types.GENERAL_MANAGE_UPDATE_COMMENT_SUCCESS: {
      const index = state.comments.findIndex(({ id }) => id === Number(action.data.id));
      if (index > -1) {
        return {
          ...state,
          isFetching: false,
          error: false,
          errorMessage: '',
          comments: [
            ...state.comments.slice(0, index),
            action.data,
            ...state.comments.slice(index + 1)]
        };
      }
      return state;
    }

    case types.GENERAL_MANAGE_UPDATE_COMMENT_FAIL:
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.data
      };

    case types.GENERAL_MANAGE_UPDATE_VULN_CLIENT_SIDE: {
      const { updatedVuln, toolName } = action.payload;
      const id = updatedVuln.vuln_id;

      const vulnsListArray = state.list.map(vuln => {
        if (vuln.id === id) {
          return {
            ...vuln,
            issuetracker: {
              ...vuln.issuetracker,
              [toolName]: vuln.issuetracker[toolName] ? [...vuln.issuetracker[toolName], updatedVuln] : [updatedVuln]
            }
          };
        }
        return vuln;
      });

      return {
        ...state,
        list: vulnsListArray
      };
    }

    case types.GENERAL_MANAGE_SET_VULNS_FILTER_ERROR: {
      return {
        ...state,
        isFetching: false,
        isFetchingExpandedData: false
      };
    }

    default: {
      return state;
    }
  }
};

export default manageGeneral;
