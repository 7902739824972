import api from 'services/api';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import * as types from './types';
import {
  selectFilters, selectGroupByField, selectFilterHistory, selectAdvancedFilter, selectCustomFilter, isFilteringBy
} from './selectors';
import { GROUP_BY } from './constants';

export const setSelectedEntity = (selectedEntity) => async (dispatch) => dispatch({ type: types.SET_SELECTED_ENTITY, selectedEntity });
const setAdvancedFilterValue = (entity, value) => async (dispatch) => dispatch({ type: types.SET_ADVANCED_FILTER, value, entity });
export const clearAdvancedFilter = (entity) => async (dispatch) => dispatch({ type: types.SET_ADVANCED_FILTER, value: '', entity });
export const setFilterError = (entity, filterError) => async (dispatch) => dispatch({ type: types.SET_FILTER_ERROR, filterError, entity });
export const clearFilterError = (entity) => async (dispatch) => dispatch({ type: types.SET_FILTER_ERROR, filterError: '', entity });
export const setAdvancedMode = (isAdvancedMode) => async (dispatch) => dispatch({ type: types.SET_ADVANCED_MODE, isAdvancedMode });
export const setSaveEnabled = (saveEnabled) => async (dispatch) => dispatch({ type: types.SET_SAVE_STATUS, saveEnabled });
export const setModelFields = (modelFields) => async (dispatch) => dispatch({ type: types.SET_MODEL_FIELDS, modelFields });

export const resetFilters = (entity) => async (dispatch) => dispatch({ type: types.RESET_FILTERS, entity });

export function setAdvancedFilter (entity, value) {
  return async (dispatch) => {
    dispatch(setSelectedEntity(entity));
    dispatch(setAdvancedFilterValue(entity, value));
  };
}

export function setOrderBy (entity, order_by) {
  return (dispatch) => {
    if (!isEmpty(order_by)) {
      const newOrderBy = order_by.map((individualOrderBy) => {
        const { id, desc } = individualOrderBy;
        const orderByIndividualObject = {
          field: id,
          direction: desc ? 'desc' : 'asc'
        };
        return orderByIndividualObject;
      });
      dispatch({ type: types.SET_ORDER_BY, entity, order_by: newOrderBy });
    }
  };
}

export const clearOrderBy = (entity) => async (dispatch) => dispatch({ type: types.CLEAR_ORDER_BY, entity });
export const setPageNumber = (entity, pageNumber) => async (dispatch) => dispatch({ type: types.SET_PAGE_NUMBER, entity, pageNumber });
export const setKeys = (entity, keys) => async (dispatch) => dispatch({ type: types.SET_KEYS, entity, keys });
export const clearKeys = (entity) => async (dispatch) => dispatch({ type: types.CLEAR_KEYS, entity });

export const addFilterHistory = (entity, filter) => async (dispatch) => dispatch({ type: types.ADD_FILTER_TO_HISTORY, entity, filter });
const overrideFilterHistory = (entity, filterHistory) => async (dispatch) => dispatch({ type: types.SET_FILTER_HISTORY, filterHistory, entity });

function overrideFilter (entity, filters) {
  return async (dispatch) => {
    dispatch(setSelectedEntity(entity));
    dispatch(setPageNumber(entity, 1));
    dispatch({ type: types.SET_FILTERS, entity, filters });
  };
}

export const clearFilters = (entity) => async (dispatch) => dispatch({ type: types.CLEAR_FILTERS, entity });

function concatenateFilters (entity, filter) {
  return async (dispatch, getState) => {
    const state = getState();
    const actualFilters = selectFilters(entity, state);
    dispatch(clearAdvancedFilter(entity));

    if (actualFilters.length === 0) {
      dispatch(overrideFilter(entity, [filter]));
    } else {
      const concatenatedFilter = [
        {
          and: [
            ...actualFilters,
            filter
          ]
        }
      ];
      dispatch(overrideFilter(entity, concatenatedFilter));
    }
  };
}

const groupFiltersByName = (filterHistory) => filterHistory.reduce(
  (result, item) => {
    if (item.name === 'service' || item.name === 'services') {
      const name = `service__${item.val.name}`;
      return ({
        ...result,
        [name]: [
          ...(result[name] || []),
          item
        ]
      });
    }
    return ({
      ...result,
      [item.name]: [
        ...(result[item.name] || []),
        item
      ]
    });
  },
  {}
);

export function rebuildFilters (entity) {
  return async (dispatch, getState) => {
    const state = getState();
    const filterHistory = selectFilterHistory(entity, state);
    dispatch({ type: types.REMOVE_FILTERS, entity });

    const groupedFiltersObject = groupFiltersByName(filterHistory);

    const groupedFiltersArray = Object.entries(groupedFiltersObject).map((filterArray) => filterArray[1]);

    groupedFiltersArray.forEach((group) => {
      let grouped = {};
      if (!isEmpty(group)) {
        if (group.length === 1) grouped = group[0];
        else if (group.some((filter) => filter.name.includes('date'))) grouped = { and: group };
        else grouped = { or: group };
      }
      dispatch(concatenateFilters(entity, grouped));
    });
  };
}

export function addFilter (entity, filter) {
  return async (dispatch, getState) => {
    const state = getState();
    const isFilterApplied = isFilteringBy(state, entity, filter);

    if (!isFilterApplied) {
      dispatch(addFilterHistory(entity, filter));
      dispatch(rebuildFilters(entity));
    }
  };
}

export function removeFilter (entity, filter) {
  return async (dispatch, getState) => {
    const state = getState();
    const filterHistory = selectFilterHistory(entity, state);
    const newFilterHistory = filterHistory.filter((f) => f.name !== filter.name || f.op !== filter.op || !isEqual(f.val, filter.val));
    dispatch(overrideFilterHistory(entity, newFilterHistory));
    dispatch(rebuildFilters(entity));
  };
}

export function setFilter (entity, filter) {
  return async (dispatch) => {
    dispatch(clearFilters(entity));
    dispatch(addFilter(entity, filter));
  };
}

// GROUP BY ACTIONS

export const clearGroupBy = (entity) => async (dispatch) => dispatch({ type: types.CLEAR_GROUP_BY, entity });

export function setGroupBy (entity, group_by) {
  return async (dispatch) => {
    if (group_by === GROUP_BY.none.key.value) {
      dispatch(setPageNumber(entity, 1));
      dispatch(clearGroupBy(entity));
      dispatch(clearFilters(entity));
    } else {
      dispatch(setPageNumber(entity, null));
      dispatch({ type: types.SET_GROUP_BY, entity, group_by });
    }
  };
}

export function setExpandedFilter (entity, expandedFilter) {
  return async (dispatch) => {
    dispatch({ type: types.SET_EXPANDED_FILTER, entity, expandedFilter });
  };
}

export function expandGroupBy (entity, data) {
  return async (dispatch, getState) => {
    const state = getState();
    const groupByField = selectGroupByField(entity, state);
    const isGroupingByService = groupByField === GROUP_BY.services__name.key.value[0].field;
    const isGroupingByHostname = groupByField === GROUP_BY.hostnames__name.key.value[0].field;
    const isGroupingByCVE = groupByField === 'cve_instances__name';
    let filterValue = data[groupByField];

    if (isGroupingByService || isGroupingByHostname) {
      filterValue = {
        name: 'name',
        op: 'eq',
        val: data[groupByField]
      };
    }
    let filter = [];

    if (isGroupingByCVE) {
      filter = [
        {
          name: 'cve_instances',
          op: 'any',
          val: {
            name: 'name',
            op: 'eq',
            val: data.cve_instances__name
          }
        },
        {
          name: 'target',
          op: 'eq',
          val: data.target
        }
      ];
    } else {
      filter = [
        {
          ...GROUP_BY[groupByField].filter,
          val: filterValue
        }
      ];
    }

    dispatch(setExpandedFilter(entity, filter));
  };
}

export function removeFiltersByKey (entity, key) {
  return async (dispatch, getState) => {
    const state = getState();
    const filterHistory = selectFilterHistory(entity, state);
    const newFilterHistory = filterHistory.filter((f) => f.name !== key);
    dispatch(overrideFilterHistory(entity, newFilterHistory));
    dispatch(rebuildFilters(entity));
  };
}

// CUSTOM FILTER ACTIONS

export const setCustomFilters = (entity, customFilters) => async (dispatch) => dispatch({ type: types.SET_CUSTOM_FILTERS, entity, customFilters });

export function applyCustomFilter (entity, id) {
  return async (dispatch, getState) => {
    const state = getState();
    const customFilter = selectCustomFilter(state, entity, id);

    if (!isEmpty(customFilter)) {
      const { isAdvancedFilter, filterHistory, json_query } = customFilter;

      dispatch(clearFilters(entity));

      if (isAdvancedFilter) {
        dispatch(setAdvancedMode(true));
        dispatch(setAdvancedFilter(entity, json_query));
      } else {
        dispatch(setAdvancedMode(false));
        dispatch(overrideFilterHistory(entity, filterHistory));
        dispatch(rebuildFilters(entity));
      }
    }
  };
}

const getMappedCustomFilters = (response, entity) => {
  const filteredEntityFilters = response.filter((filter) => (JSON.parse(filter.user_query).entity === entity));

  return filteredEntityFilters.map((filter) => {
    // eslint-disable-next-line object-curly-newline
    const { id, name, json_query, user_query } = filter;
    const { isAdvancedFilter, filterHistory } = JSON.parse(user_query);
    // eslint-disable-next-line object-curly-newline
    const formedCustomFilter = { id, name, isAdvancedFilter, filterHistory, json_query };

    return formedCustomFilter;
  });
};

export function getCustomFilters () {
  return async (dispatch) => {
    try {
      const response = await api.filters.getCustomFilters();
      const vulnMappedCustomFilters = getMappedCustomFilters(response, 'vulns');
      const assetMappedCustomFilters = getMappedCustomFilters(response, 'assets');

      dispatch(setCustomFilters('vulns', vulnMappedCustomFilters));
      dispatch(setCustomFilters('assets', assetMappedCustomFilters));
    } catch (e) {
      dispatch(setFilterError('vulns', 'There was a problem when fetching custom filters. Please try again.'));
      dispatch(setFilterError('assets', 'There was a problem when fetching custom filters. Please try again.'));
    }
  };
}

export function createCustomFilter (entity, name) {
  return async (dispatch, getState) => {
    const state = getState();
    const standardFilter = selectFilters(entity, state);
    const advancedFilter = selectAdvancedFilter(state, entity);
    const filterHistory = selectFilterHistory(entity, state);

    let json_query = {};
    let user_query = {};

    if (advancedFilter) {
      json_query = JSON.parse(`${advancedFilter}`);
      user_query = {
        entity,
        isAdvancedFilter: true,
        filterHistory: []
      };
    } else {
      json_query = standardFilter[0];
      user_query = {
        entity,
        isAdvancedFilter: false,
        filterHistory
      };
    }

    const data = {
      name,
      json_query: JSON.stringify(json_query),
      user_query: JSON.stringify(user_query)
    };

    try {
      await api.filters.createCustomFilter(data);
      dispatch(getCustomFilters());
    } catch (e) {
      dispatch(setFilterError(entity, 'There was a problem when saving custom filter. Please try again.'));
    }
  };
}

export function removeCustomFilter (entity, id) {
  return async (dispatch) => {
    if (id) {
      try {
        await api.filters.removeCustomFilter(id);
        dispatch(getCustomFilters());
      } catch (e) {
        dispatch(setFilterError(entity, 'There was a problem when removing custom filter. Please try again.'));
      }
    }
  };
}

export function getModelFields () {
  return async (dispatch) => {
    try {
      const response = await api.filters.getModelFields();
      dispatch(setModelFields({
        vulnerability: response.vulnerability,
        asset: response.host
      }));
    } catch (e) {
      // TODO handle error
    }
  };
}

export function storeDashboardFilter (filter, status) {
  return dispatch => {
    dispatch({ type: types.SAVE_DASHBOARD_TEMPLATE, filter, status });
  };
}
