import GitInfo from 'react-git-info/macro';
import api from 'services/api';
import { WORKSPACE_SELECTION, WORKSPACE_NAME } from 'Screens/Wizard/constants/index';
import {
  workspaceSelectedForWorking,
  setUrlReturn
} from 'Screens/Faraday/actions/Actions';
import { SET_STEP_WIZARD, toggleAutoSelectWs } from 'Screens/Wizard/actions/Actions';
import { getWorkspaces, getWorkspaceStats, setWsStatsError } from 'Screens/Workspaces/actions/Actions';
import Cookies from 'universal-cookie';
import get from 'lodash/get';
import head from 'lodash/head';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';

import { SAVE_PREFERENCES_SUCCESS, updatePreferences } from 'store/Preferences/actions';
import { untrackUser, trackUser, trackEvent } from 'tracking/GA';
import mappedPermissions from 'Hooks/usePermission/mappedPermissions';
import { saveConfig } from 'store/Config/actions';
import { ACTIONS, CATEGORIES } from 'tracking/GA/constants';
import { redirect } from 'store/Router/actions';
import { selectAutoSelectWs, selectWorkspaceActivity } from 'store/Preferences/workspace/selectors';
import { selectIsLoggedIn, selectRememberMe, selectUserRoles, selectRequest2fa } from 'store/Sesion/selectors';
import { selectActiveWorkspaces } from 'store/Workspace/selectors';
import { selectLocation, selectPathname } from 'store/Router/selectors';
import { selectUrlReturn } from 'store/Faraday/selectors';
import { setError } from 'store/errors/actions';
import { GET_WS_STATS_START, GET_WS_STATS_SUCCESS } from 'Screens/Dashboard/actions/Actions';
import { removeUnfinishedCommands, showProcessingQueue } from 'Common/Components/FileUploadContext/actions';
import { selectShowLegacyUI } from 'store/Preferences/selectors';
import { selectIsSsoEnabled } from 'store/Config/selector';

export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN_FETCH_FINISHED = 'LOGIN_FETCH_FINISHED';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const HIDE_ERROR_MESSAGE_LOGIN = 'HIDE_ERROR_MESSAGE_LOGIN';
export const LOGOUT_SUCCESS_REDIRECT = 'LOGOUT_SUCCESS_REDIRECT';
export const NOT_LOGGED = 'NOT_LOGGED';

export const REQUEST_SECOND_FACTOR_LOGIN = 'REQUEST_SECOND_FACTOR_LOGIN';
export const LOAD_PREFERENCES = 'LOAD_PREFERENCES';
export const SET_INITIAL_STEP_WIZARD = 'SET_INITIAL_STEP_WIZARD';
export const SET_FIRST_LOGIN = 'SET_FIRST_LOGIN';
export const SET_REMEMBER_ME = 'SET_REMEMBER_ME';
export const LOGIN_TO_INACTIVE_WORKSPACE_ERROR = 'LOGIN_TO_INACTIVE_WORKSPACE_ERROR';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const LOGIN_RESET = 'LOGIN_RESET';
export const LOGIN_2FA_START = 'LOGIN_2FA_START';
export const VALIDATING_SESSION_START = 'VALIDATING_SESSION_START';
export const VALIDATING_SESSION_END = 'VALIDATING_SESSION_END';
export const SAVE_USER = 'SAVE_USER';

function failureLoginCallback () {
  return (dispatch) => {
    dispatch({ type: LOGIN_FAIL, data: { message: 'An error has occurred.' } });
  };
}

export function failureSessionValidationCallback () {
  return (dispatch) => {
    dispatch({ type: NOT_LOGGED });
  };
}

export function startWizard (workspaces) {
  return (dispatch) => {
    dispatch({
      type: SET_INITIAL_STEP_WIZARD, step: WORKSPACE_SELECTION, workspaces, width: '450px', height: '506px'
    });
    dispatch(redirect('/wizard', false));
  };
}

function validateAutoLoginWs (workspaceName) {
  return async (dispatch, getState) => {
    const { workspaces } = getState().workspace;
    const workspaceToLogIn = workspaces.find((ws) => ws.name === workspaceName);
    if (workspaceToLogIn) {
      const isActive = get(workspaceToLogIn, 'active', false);
      if (isActive) {
        dispatch({ type: GET_WS_STATS_START });
        dispatch(workspaceSelectedForWorking(workspaceName));
        dispatch(redirect(`/feed/${workspaceName}`));
        try {
          const response = await api.workspace.getWsStats(workspaceName);
          dispatch(getWorkspaceStats(response));
          dispatch({ type: GET_WS_STATS_SUCCESS });
        } catch (e) {
          dispatch(setWsStatsError(e.message));
        }
      } else {
        dispatch(toggleAutoSelectWs(false));
        dispatch({ type: LOGIN_TO_INACTIVE_WORKSPACE_ERROR, errorMessage: 'Attempted to log into an inactive Workspace' });
        dispatch(startWizard(workspaces));
      }
    }
  };
}

export function setUser (user, roles, csrfToken) {
  return (dispatch) => {
    dispatch({
      type: LOGIN_SUCCESS, user, roles, csrfToken
    });
  };
}

export function getApiConfig () {
  return async (dispatch) => {
    let config = {};
    try {
      config = await api.faraday.fetchApiConfig();

      if (config.client_key === undefined) config.client_key = 'white';

      dispatch(saveConfig(config));
    } catch (e) {
      dispatch(setError('There was an error while fetching config'));
    }
    return config;
  };
}

function successSessionCallback (data) {
  return async (dispatch) => {
    const gitInfo = GitInfo();
    const config = await dispatch(getApiConfig());

    let licenseStatus;
    try {
      licenseStatus = await api.faraday.getLicense(config);
    } catch (e) {
      // TODO handle error
    }

    await dispatch({
      type: LOAD_PREFERENCES,
      permissions: mappedPermissions(data.permissions),
      hash: gitInfo.commit.shortHash,
      role: data.roles[0],
      edate: config.edate,
      email: config.email,
      expired: config.expired,
      wsPort: config.websocket_port,
      notificationsEnabled: config.notifications_enabled,
      licenseType: config.type,
      licenseStatus,
      userName: data.name,
      user_id: data.user_id,
      featureFlags: data.feature_flags
    });

    await dispatch({ type: SAVE_PREFERENCES_SUCCESS, preferences: data.preferences || {} });

    await dispatch(setUser(data.name, data.roles, data.csrf_token));

    // obtengo workspaces, esto se usa en todos lados.
    await dispatch(getWorkspaces(false, false, true));
  };
}

export function createSession () {
  return async (dispatch) => {
    try {
      const data = await api.faraday.getSession();
      await dispatch(successSessionCallback(data));
    } catch (e) {
      await dispatch(failureSessionValidationCallback());
    }
  };
}

function twoFaSuccess (response, user) {
  return (dispatch) => {
    dispatch({ type: SAVE_USER, user });
    if (response.code === 202) {
      dispatch({ type: REQUEST_SECOND_FACTOR_LOGIN });
    } else {
      dispatch(createSession());
    }
  };
}

export function setFirstLogin (workspace = 'faraday_beta') {
  return async (dispatch) => {
    await dispatch({ type: SET_FIRST_LOGIN, firstLogin: true });
    await dispatch(workspaceSelectedForWorking(workspace));
    dispatch(redirect('/wizard', false));
  };
}

export function createNewWorkspace () {
  return (dispatch) => {
    dispatch({ type: SET_STEP_WIZARD, step: WORKSPACE_NAME, createNew: true });
    dispatch(redirect('/wizard'));
  };
}

export function loginRedirect () {
  return async (dispatch, getState) => {
    const autoSelect = selectAutoSelectWs(getState()); // opcion autoselec, true|false
    const workspaceActivity = selectWorkspaceActivity(getState()); // ultimos workspaces usados
    const workspaces = selectActiveWorkspaces(getState()); // listado de todos los workspaces
    const roles = selectUserRoles(getState()); // roles del usuario
    const urlReturn = selectUrlReturn(getState());
    const pathname = selectPathname(getState());
    const showLegacyUI = selectShowLegacyUI(getState());
    const ssoEnabled = selectIsSsoEnabled(getState());
    const hasWsAutoselected = autoSelect && !isEmpty(workspaceActivity);
    const request2fa = selectRequest2fa(getState());

    if (urlReturn &&
        !['/login', '/logout', '/'].includes(urlReturn) &&
        !urlReturn.startsWith('/resetpass') && urlReturn !== '/' && !request2fa) {
      dispatch(redirect(urlReturn));
      dispatch(setUrlReturn(''));
    } else if (includes(roles, 'admin') && workspaces.length === 0) {
      dispatch(createNewWorkspace());
    } else if ((((pathname === '/') && ssoEnabled) || hasWsAutoselected) && !request2fa) {
      dispatch(redirect('/pick-version'));
    } else if ((pathname === '/') && !showLegacyUI) {
      dispatch(redirect('/workspaces'));
    } else if ((pathname === '/') && showLegacyUI) {
      dispatch(startWizard(workspaces));
    } else if (((pathname !== '/') || (urlReturn !== '/')) && !request2fa) {
      dispatch(redirect('/pick-version'));
    }
  };
}

export function validateSession () {
  return async (dispatch, getState) => {
    const location = selectLocation(getState());
    let isLoggedIn = selectIsLoggedIn(getState());
    dispatch({ type: VALIDATING_SESSION_START });

    if (!isLoggedIn && (
      !includes(['/logout', '/forgotpass'], location.pathname) &&
        !location.pathname.startsWith('/resetpass'))
    ) {
      await dispatch(createSession()); // valido y creo la sesion usando la cookie del browser.
    }
    dispatch({ type: VALIDATING_SESSION_END });

    isLoggedIn = selectIsLoggedIn(getState());

    if (isLoggedIn && includes(['/login', '/'], location.pathname)) {
      dispatch(loginRedirect()); // si esta logueado lo redirecciono a donde corresponde
    } else if (!isLoggedIn && (
      !includes(['/logout', '/forgotpass'], location.pathname) &&
        !location.pathname.startsWith('/resetpass'))) {
      dispatch(redirect('/login')); // si no esta logueado va al login
    }
    // por default queda donde está
  };
}

export function loginStart () {
  return (dispatch) => {
    dispatch({ type: LOGIN_START });
  };
}

export function login (user, password) {
  return async (dispatch, getState) => {
    try {
      const remember = selectRememberMe(getState());

      dispatch(trackEvent(CATEGORIES.account, ACTIONS.logIn.name, ACTIONS.logIn.label));

      const response = await api.auth.login({ email: user || '', password: password || '', remember });
      dispatch(trackUser(user));

      if (response.code === 202) {
        dispatch({ type: SAVE_USER, user });
        dispatch({ type: REQUEST_SECOND_FACTOR_LOGIN });
        dispatch(loginRedirect());
      }
      // reconstruye sesion
      await dispatch(createSession());

      // todo ok => redirecciona a donde corresponda
      if (response.code === 200) {
        dispatch(loginRedirect());
      }

      // Force loading spinner of the Login button to change after the redirect
      dispatch({ type: LOGIN_FETCH_FINISHED });
    } catch (e) {
      dispatch(failureLoginCallback());
    }
  };
}

// TODO: Must comment line 196 in app.py of faraday
export function logOut () {
  return async (dispatch) => {
    try {
      const cookies = new Cookies();
      cookies.remove('remember_token');
      await api.auth.logout();
      localStorage.removeItem('hideFeedback');
      dispatch({ type: LOGOUT_SUCCESS });
      dispatch(untrackUser());
      dispatch(removeUnfinishedCommands());
      dispatch(showProcessingQueue(false));
    } catch (e) {
      dispatch({ type: LOGOUT_SUCCESS_REDIRECT });
    }
    dispatch(redirect('/login', false));
  };
}

export function hideErrorMessage () {
  return (dispatch) => {
    dispatch({ type: HIDE_ERROR_MESSAGE_LOGIN });
  };
}

function twoFaFail () {
  return (dispatch) => {
    dispatch({ type: LOGIN_FAIL, data: { message: 'Invalid token.' } });
  };
}

export function login2fa (token, user) {
  return async (dispatch) => {
    dispatch({ type: LOGIN_2FA_START });
    try {
      const response = await api.auth.twoFaConfirm({ secret: token });
      await dispatch(twoFaSuccess(response, user));

      dispatch({ type: LOGIN_FETCH_FINISHED });

      // reconstruye sesion
      await dispatch(createSession());

      // todo ok => redirecciona a donde corresponda
      dispatch(loginRedirect());
    } catch (e) {
      dispatch(twoFaFail());
    }
  };
}

export function setRememberMe (value) {
  return (dispatch) => {
    dispatch({ type: SET_REMEMBER_ME, value });
  };
}

export function forgotPassword (email) {
  return async (dispatch) => {
    dispatch({ type: FORGOT_PASSWORD_REQUEST });
    try {
      await api.faraday.forgotPassword(email);
      return dispatch({ type: FORGOT_PASSWORD_SUCCESS, email });
    } catch (e) {
      dispatch({ type: FORGOT_PASSWORD_FAILURE, data: { message: 'An error has occurred.' } });
      return dispatch(setError(e.response.message));
    }
  };
}

export function resetPassword (hash, password, repeatPassword) {
  return async (dispatch) => {
    dispatch({ type: RESET_PASSWORD_REQUEST });
    try {
      await api.faraday.resetPassword(hash, password, repeatPassword);
      return dispatch({ type: RESET_PASSWORD_SUCCESS });
    } catch (e) {
      return dispatch({ type: RESET_PASSWORD_FAILURE, data: { message: 'An error has occurred.' } });
    }
  };
}

export function resetLogin () {
  return async (dispatch) => {
    dispatch({ type: LOGIN_RESET });
  };
}

export function redirectToVersion (legacy, showTour) {
  return async (dispatch, getState) => {
    const workspaces = selectActiveWorkspaces(getState());
    const autoSelect = selectAutoSelectWs(getState());
    const workspaceActivity = selectWorkspaceActivity(getState());

    if (legacy) {
      await dispatch(updatePreferences({ legacyUI: legacy, tourFinished: showTour }));
      if (autoSelect && !isEmpty(workspaceActivity)) dispatch(validateAutoLoginWs(head(workspaceActivity)));
      else dispatch(startWizard(workspaces));
    } else {
      await dispatch(updatePreferences({ legacyUI: legacy, sidebarTourFinished: showTour, dashboardTourFinished: showTour, assetVulnsTourFinished: showTour, vulnsTourFinished: showTour }));
      if (autoSelect && !isEmpty(workspaceActivity)) dispatch(validateAutoLoginWs(head(workspaceActivity)));
      else dispatch(redirect('/workspaces'));
    }
  };
}
