import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';
import CopyLink from './components/CopyLink';
import CopyApiLink from './components/CopyApiLink';
import { Wrapper, InputTitle, IconVulnerability } from './styled';
import { selectIsReadOnly } from 'store/Faraday/selectors';
import { selectVulnDetail } from 'store/ManageGeneral/selectors';
import { updateVulnGeneral } from 'store/ManageGeneral/actions';

const Title = () => {
  const dispatch = useDispatch();
  const vulnDetail = useSelector(selectVulnDetail);
  const [vulnName, setVulnName] = useState(vulnDetail.name);
  const readOnly = useSelector(selectIsReadOnly);

  useEffect(() => {
    setVulnName(vulnDetail.name);
  }, [vulnDetail]);

  const updateName = useRef(debounce((name, vuln) => {
    if (name) {
      dispatch(updateVulnGeneral(vuln, 'name', name));
    }
  }, 1000));

  const handleChange = (e) => {
    const name = e.target.value;
    setVulnName(name);
    updateName.current(name, vulnDetail);
  };

  return (
    <Wrapper>
      <IconVulnerability />
      <InputTitle value={ vulnName } onChange={ handleChange } disabled={ readOnly } />
      <CopyLink />
      <CopyApiLink />
    </Wrapper>
  );
};

export default Title;
