import TABS from 'Screens/Contextualization/VulnsGeneral/components/VulnerabilityDetail/components/Tabs/components/TabSelector/tabs';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';

export const selectError = (state) => get(state, 'manageGeneral.error', false);
export const selectErrorMessage = (state) => get(state, 'manageGeneral.errorMessage', '');
export const selectVulns = (state) => get(state, 'manageGeneral.list', []);
export const selectIsFetching = (state) => get(state, 'manageGeneral.isFetching', false);

export const selectShowDetail = (state) => get(state, 'manageGeneral.showVulnDetail', false);
export const selectVulnDetail = (state) => get(state, 'manageGeneral.vulnDetail', []);
export const selectVulnDetailId = (state) => get(state, 'manageGeneral.vulnDetail._id', 0);
export const selectVulnDetailName = (state) => get(state, 'manageGeneral.vulnDetail.name', '');
export const selectVulnDetailOwned = (state) => get(state, 'manageGeneral.vulnDetail.owned', false);
export const selectVulnDetailPorts = (state) => get(state, 'manageGeneral.vulnDetail.ports', '');
export const selectVulnDetailProtocol = (state) => get(state, 'manageGeneral.vulnDetail.protocol', '');
export const selectVulnDetailVersion = (state) => get(state, 'manageGeneral.vulnDetail.version', '');
export const selectVulnDetailDescription = (state) => get(state, 'manageGeneral.vulnDetail.description', '');
export const selectVulnDetailStatus = (state) => get(state, 'manageGeneral.vulnDetail.status', '');
export const selectVulnWorkspace = (state) => get(state, 'manageGeneral.vulnDetail.workspace_name', '');
export const selectVulnsCount = (state) => get(state, 'manageGeneral.count', 0);
export const selectVulnsSelected = (state) => get(state, 'manageGeneral.vulnsSelected', []);
export const selectLastSelected = (state) => get(state, 'manageGeneral.lastSelected', -1);
export const allVulnsAreSelected = (state) => {
  const vulns = selectVulns(state);
  const vulnList = selectVulnsSelected(state);
  return vulns.every((testVuln) => vulnList.some((vuln) => vuln._id === testVuln._id));
};
export const someVulnIsSelected = (state) => {
  return selectVulnsSelected(state).length > 0;
};

export const selectSelectAll = (state) => get(state, 'manageGeneral.selectAll', false);

export const selectCurrentGeneralVuln = (state) => get(state, 'manageGeneral.vulnSelectedForWorking[0]', {});
export const selectShowDeleteConfirmation = (state) => get(state, 'manageGeneral.showDeleteConfirmation', false);

export const selectIsFetchingExpandedData = (state) => get(state, 'manageGeneral.isFetchingExpandedData', false);
export const selectVulnsGroupedData = (state, index) => get(state, `manageGeneral.list.${index}.groupData`, []);

export const selectShowContextMenu = (state) => get(state, 'manageGeneral.showContextMenu', false);
export const selectContextMenuXPos = (state) => get(state, 'manageGeneral.contextMenuXPos', 0);
export const selectContextMenuYPos = (state) => get(state, 'manageGeneral.contextMenuYPos', 0);

export const selectModalBulkUpdateField = (state) => get(state, 'manageGeneral.bulkUpdateField', '');
export const selectModalBulkUpdateValue = (state) => get(state, 'manageGeneral.bulkUpdateValue', '');

export const selectCurrentReferences = (state) => {
  const detail = selectVulnDetail(state);
  return sortBy(get(detail, 'refs', []));
};

export const selectVulnDetailSelectedTabId = (state) => get(state, 'manageGeneral.vulnDetailSelectedTab', 'general');
export const selectVulnDetailSelectedTab = (state) => {
  const selectedTabId = selectVulnDetailSelectedTabId(state);
  const defaultTab = TABS.find((tab) => tab.id === 'general');
  const selectedTab = TABS.find((tab) => tab.id === selectedTabId) || defaultTab;
  return selectedTab;
};

export const selectShowManageRightFilters = (state) => get(state, 'manageGeneral.showRightFilters', false);

export const selectVulnDetailTags = (state) => get(state, 'manageGeneral.vulnDetail.tags', null);

export const selectVulnsTags = (state) => {
  const vulnsSelected = selectVulnsSelected(state);
  const vulnsTags = vulnsSelected.flatMap(vuln => vuln.tags);
  return vulnsTags;
};

export const selectParsedIssueTrackersContext = (state) => {
  const currentVuln = selectVulnDetail(state);
  const issuetracker = get(currentVuln, 'issuetracker', '');
  const parsedIssueTrackers = [];

  for (const tool in issuetracker) {
    if (issuetracker[tool].length >= 0) {
      issuetracker[tool].forEach((issue) => {
        parsedIssueTrackers.push({ url: issue.url, text: tool.toUpperCase() });
      });
    }
    if (!Array.isArray(issuetracker[tool])) {
      parsedIssueTrackers.push({ url: issuetracker[tool].url, text: tool.toUpperCase() });
    }
  }

  return parsedIssueTrackers;
};

export const selectIsFetchingVulnEnrichment = (state) => get(state, 'manageGeneral.isFetchingVulnEnrichment', false);

export const selectDetailDuplicates = (state) => get(state, 'manageGeneral.vulnDetailDuplicates', []);

export const selectSelectedMainVulns = (state) => {
  const selectedVulns = selectVulnsSelected(state);
  const mainVulns = selectedVulns.filter((v) => !isEmpty(v.duplicates_associated)).map((v) => ({ id: v.id, name: v.name }));
  const duplicatedVulns = selectedVulns.filter((v) => !isEmpty(v.duplicates_main));
  const relativeMains = duplicatedVulns.map((v) => ({ id: v.duplicates_main.id, name: v.duplicates_main.name }));

  const mainsInGroup = [...mainVulns, ...relativeMains];
  const set = new Set();
  const uniqueMains = mainsInGroup.filter((vuln) => (set.has(vuln.id) ? false : set.add(vuln.id)));

  return uniqueMains;
};

export const selectSelectedComment = (state) => get(state, 'manageGeneral.selectedComment', null);
export const selectComments = (state) => get(state, 'manageGeneral.comments', []);
