import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSelectedEntity, selectFilterError, selectAdvancedFilter, selectSaveEnabled
} from 'store/Filters/selectors';
import {
  setSelectedEntity, setAdvancedFilter, setFilterError, clearFilterError, setSaveEnabled, clearAdvancedFilter
} from 'store/Filters/actions';
import { SEARCH_BY_ENTITIES, ENTITIES } from 'store/Filters/entitiesConstants';
import get from 'lodash/get';
import { selectLocation } from 'store/Router/selectors';
import { trackEvent } from 'tracking/GA';
import { ACTIONS, CATEGORIES } from 'tracking/GA/constants';
import {
  Input, SearchInput, SearchIcon, ClearIcon, SearchButton
} from '../BasicFilter/styled';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import { selectCurrentHost } from 'store/Host/selectors';

const AdvancedFilter = () => {
  const [advancedFilterValue, setAdvancedFilterValue] = useState('');
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const location = useSelector(selectLocation);
  const currentWorkspace = useSelector(selectCurrentWorkspace);
  const currentAsset = useSelector(selectCurrentHost);
  const assetId = get(currentAsset, '_id', 0);
  const selectedEntity = useSelector(getSelectedEntity);
  const searchFunction = get(ENTITIES, `${selectedEntity}.searchFunction`, null);
  const storedAdvancedFilter = useSelector((state) => selectAdvancedFilter(state, selectedEntity));
  const filterError = useSelector((state) => selectFilterError(selectedEntity, state));
  const saveEnabled = useSelector(selectSaveEnabled);

  const trackSearch = () => {
    const trackAction = get(ACTIONS, `search.${selectedEntity}.avanced`, '');
    if (trackAction) dispatch(trackEvent(CATEGORIES.search, trackAction.name, trackAction.label));
  };

  const setInputBlur = () => inputRef.current && inputRef.current.blur();

  const currentLocationEntity = () => {
    if (location.pathname.includes(`host/${currentWorkspace}/${assetId}/vulns`)) return ENTITIES.vulnsAssets;
    else if (location.pathname.includes('/hosts')) return ENTITIES.assetsGeneral;
    else return SEARCH_BY_ENTITIES.find((e) => location.pathname.includes(e.pathname));
  };

  useEffect(() => {
    const entity = get(currentLocationEntity(), 'name.value', '');
    if (entity) dispatch(setSelectedEntity(entity));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    setAdvancedFilterValue(storedAdvancedFilter);
  }, [storedAdvancedFilter]);

  useEffect(() => {
    if (saveEnabled) dispatch(setSaveEnabled(false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, advancedFilterValue]);

  const validateFilterSyntax = () => {
    if (filterError) dispatch(clearFilterError(selectedEntity));
    try {
      const queryToParse = `{"filters":[${advancedFilterValue}]}`;
      JSON.parse(queryToParse);
      dispatch(setAdvancedFilter(selectedEntity, advancedFilterValue));
      dispatch(setSaveEnabled(true));
      dispatch(searchFunction());
      trackSearch();
      setInputBlur();
    } catch (e) {
      dispatch(setFilterError(selectedEntity, 'Syntax error. Please try again. For further help check our documentation'));
    }
  };

  const onAdvancedKeyUp = (e) => {
    if (e.key === 'Enter') {
      if (selectedEntity && advancedFilterValue) validateFilterSyntax();
    } else if (e.key === 'Escape') {
      setInputBlur();
    }
  };

  const onAdvancedKeyDown = (e) => {
    if (e.key === 'Escape') {
      setAdvancedFilterValue('');
    } else if (e.key === 'Backspace') {
      if (!advancedFilterValue) {
        dispatch(setSaveEnabled(false));
      }
    }
  };

  const onDelete = () => {
    setAdvancedFilterValue('');
    setInputBlur();
    if (selectedEntity) {
      dispatch(clearFilterError(selectedEntity));
      dispatch(clearAdvancedFilter(selectedEntity));
      dispatch(searchFunction());
    }
  };

  return (
    <>
      <SearchInput>
        <Input
          ref={ inputRef }
          value={ advancedFilterValue }
          type="text"
          placeholder={ currentLocationEntity().advancedPlaceholder }
          onChange={ ({ target }) => setAdvancedFilterValue(target.value) }
          onBlur={ ({ target }) => setAdvancedFilterValue(target.value) }
          onKeyDown={ onAdvancedKeyDown }
          onKeyUp={ onAdvancedKeyUp }
        />
      </SearchInput>
      { advancedFilterValue && <ClearIcon onClick={ onDelete } /> }
      <SearchButton onClick={ () => { if (selectedEntity && advancedFilterValue) validateFilterSyntax(); } } label={ <SearchIcon /> } />
    </>
  );
};

export default AdvancedFilter;
