export const SET_IMAGE_PREVIEW = 'SET_IMAGE_PREVIEW';
export const UPDATE_VULNS_LIST = 'UPDATE_VULNS_LIST';
export const SET_VULNS_FILTER_ERROR = 'SET_VULNS_FILTER_ERROR';
export const SEARCH_VULNS_START = 'SEARCH_VULNS_START';
export const SEARCH_VULNS_FAIL = 'SEARCH_VULNS_FAIL';
export const VULN_SELECTED = 'VULN_SELECTED';
export const VULN_UNSELECTED = 'VULN_UNSELECTED';
export const SHOW_MODAL_DELETE_CONFIRMATION_VULNS = 'SHOW_MODAL_DELETE_CONFIRMATION_VULNS';
export const HIDE_MODAL_DELETE_CONFIRMATION_VULNS = 'HIDE_MODAL_DELETE_CONFIRMATION_VULNS';
export const DELETE_VULNS = 'DELETE_VULNS';
export const HIDE_MODAL_DELETE_CONFIRMATION_VULNS_SUCCEED = 'HIDE_MODAL_DELETE_CONFIRMATION_VULNS_SUCCEED';
export const RESET_STATE_MANAGE = 'RESET_STATE_MANAGE';
export const EXPORT_CSV_MANAGE_SUCCESS = 'EXPORT_CSV_MANAGE_SUCCESS';
export const EXPORT_CSV_MANAGE_FAILURE = 'EXPORT_CSV_MANAGE_FAILURE';
export const CONFIRMATION_CHANGE_START = 'CONFIRMATION_CHANGE_START';
export const CONFIRMATION_CHANGE_SUCCESS = 'CONFIRMATION_CHANGE_SUCCESS';
export const CONFIRMATION_CHANGE_FAIL = 'CONFIRMATION_CHANGE_FAIL';
export const GET_GROUPED_DATA_START = 'GET_GROUPED_DATA_START';
export const GET_GROUPED_DATA_SUCCESS = 'GET_GROUPED_DATA_SUCCESS';
export const RESET_ERROR_VALUE = 'RESET_ERROR_VALUE';
export const SHOW_MANAGE_LEFT_FILTERS = 'SHOW_MANAGE_LEFT_FILTERS';
export const LOAD_VULNERABILITY_DETAIL_REQUEST = 'LOAD_VULNERABILITY_DETAIL_REQUEST';
export const LOAD_VULNERABILITY_DETAIL_SUCCESS = 'LOAD_VULNERABILITY_DETAIL_SUCCESS';
export const LOAD_VULNERABILITY_DETAIL_FAILURE = 'LOAD_VULNERABILITY_DETAIL_FAILURE';
export const LOAD_EVIDENCE_REQUEST = 'LOAD_EVIDENCE_REQUEST';
export const LOAD_EVIDENCE_SUCCESS = 'LOAD_EVIDENCE_SUCCESS';
export const LOAD_EVIDENCE_FAILURE = 'LOAD_EVIDENCE_FAILURE';
export const HIDE_VULNERABILITY_DETAIL = 'HIDE_VULNERABILITY_DETAIL';
export const SET_VULNERABILITY_DETAIL_TAB = 'SET_VULNERABILITY_DETAIL_TAB';
export const UPDATE_VULN_MANAGE_PREVIEW_SUCCESS = 'UPDATE_VULN_MANAGE_PREVIEW_SUCCESS';
export const UPDATE_VULN_MANAGE_PREVIEW_FAILURE = 'UPDATE_VULN_MANAGE_PREVIEW_FAILURE';
export const SELECT_GROUP_MANAGE = 'SELECT_GROUP_MANAGE';
export const SELECT_VULN_GROUP_MANAGE = 'SELECT_VULN_GROUP_MANAGE';

export const MANAGE_SET_BULK_UPDATE_FIELD = 'MANAGE_SET_BULK_UPDATE_FIELD';
export const MANAGE_SET_BULK_UPDATE_SUCCESS = 'MANAGE_SET_BULK_UPDATE_SUCCESS';
export const MANAGE_SET_BULK_UPDATE_VALUE = 'MANAGE_SET_BULK_UPDATE_VALUE';
export const MANAGE_ADD_BULK_UPDATE_VALUE = 'MANAGE_ADD_BULK_UPDATE_VALUE';
export const MANAGE_REMOVE_BULK_UPDATE_VALUE = 'MANAGE_REMOVE_BULK_UPDATE_VALUE';
export const MANAGE_BULK_UPDATE_FINISHED = 'MANAGE_BULK_UPDATE_FINISHED';
export const MANAGE_BULK_SAVE_TEMPLATE_FINISHED = 'MANAGE_BULK_SAVE_TEMPLATE_FINISHED';
export const GET_TEMPLATES_BULK_START = 'GET_TEMPLATES_BULK_START';
export const GET_TEMPLATES_BULK_SUCCESS = 'GET_TEMPLATES_BULK_SUCCESS';
export const GET_TEMPLATES_BULK_FAIL = 'GET_TEMPLATES_BULK_FAIL';
export const GET_VULNS_SUCCESS_MANAGE = 'GET_VULNS_SUCCESS_MANAGE';
export const ADD_MOCK_VULN = 'ADD_MOCK_VULN';
export const REMOVE_MOCK_VULN = 'REMOVE_MOCK_VULN';
export const MANAGE_VULN_ERROR = 'MANAGE_VULN_ERROR';
export const REFRESH_VULNS = 'REFRESH_VULNS';
export const READ_ONLY_ERROR = 'READ_ONLY_ERROR';
export const NEW_PIVOT = 'NEW_PIVOT';
export const SELECT_ALL_VULN = 'SELECT_ALL_VULN';
export const UNSELECT_ALL_VULN = 'UNSELECT_ALL_VULN';
export const SHOW_CONTEXT_MENU = 'SHOW_CONTEXT_MENU';
export const SHOW_QUICK_PLANNER = 'SHOW_QUICK_PLANNER';

export const SET_TAGS_REQUEST = 'SET_TAGS_REQUEST';
export const SET_TAGS_SUCCESS = 'SET_TAGS_SUCCESS';
export const SET_TAGS_FAIL = 'SET_TAGS_FAIL';

export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const SET_SELECTED_COMMENT = 'SET_SELECTED_COMMENT';

export const DUPLICATES_ERROR = 'DUPLICATES_ERROR';
export const DUPLICATES_MARK_SUCCESS = 'DUPLICATES_MARK_SUCCESS';
export const DUPLICATES_MARK_AS_MAIN_SUCCESS = 'DUPLICATES_MARK_AS_MAIN_SUCCESS';
export const DUPLICATES_REMOVE_SUCCESS = 'DUPLICATES_REMOVE_SUCCESS';
export const DUPLICATES_GET_SUCCESS = 'DUPLICATES_GET_SUCCESS';

export const GET_COMMENTS_REQUEST = 'GET_COMMENTS_REQUEST';
export const GET_COMMENTS_SUCCESS = 'GET_COMMENTS_SUCCESS';
export const GET_COMMENTS_FAIL = 'GET_COMMENTS_FAIL';

export const ADD_COMMENT_REQUEST = 'ADD_COMMENT_REQUEST';
export const ADD_COMMENT_SUCCESS = 'ADD_COMMENT_SUCCESS';
export const ADD_COMMENT_FAIL = 'ADD_COMMENT_FAIL';

export const REMOVE_COMMENT_REQUEST = 'REMOVE_COMMENT_REQUEST';
export const REMOVE_COMMENT_SUCCESS = 'REMOVE_COMMENT_SUCCESS';
export const REMOVE_COMMENT_FAIL = 'REMOVE_COMMENT_FAIL';

export const UPDATE_COMMENT_REQUEST = 'UPDATE_COMMENT_REQUEST';
export const UPDATE_COMMENT_SUCCESS = 'UPDATE_COMMENT_SUCCESS';
export const UPDATE_COMMENT_FAIL = 'UPDATE_COMMENT_FAIL';

export const CANCEL_FIELD_EDIT_MANAGE = 'CANCEL_FIELD_EDIT_MANAGE';
export const SAVE_TEMPLATE_MANAGE = 'SAVE_TEMPLATE_MANAGE';
export const CLOSE_TEMPLATE_MODAL_MESSAGE = 'CLOSE_TEMPLATE_MODAL_MESSAGE';

export const TAGS_UPDATE_VULN_DETAIL = 'TAGS_UPDATE_VULN_DETAIL';
export const VULN_REFRESH_ENRICHMENT = 'VULN_REFRESH_ENRICHMENT';
export const SELECT_TOTAL_VULNS = 'SELECT_TOTAL_VULNS';
export const UNSELECT_TOTAL_VULNS = 'UNSELECT_TOTAL_VULNS';
export const SET_ERROR = 'SET_ERROR';

export const MANAGE_SET_TEMP_CVSS = 'MANAGE_SET_TEMP_CVSS';
export const UPDATE_VULN_CLIENT_SIDE = 'UPDATE_VULN_CLIENT_SIDE';

export const REFRESH_VULN_ENRICHMENT_REQUEST = 'REFRESH_VULN_ENRICHMENT_REQUEST';
export const REFRESH_VULN_ENRICHMENT_SUCCESS = 'REFRESH_VULN_ENRICHMENT_SUCCESS';
export const REFRESH_VULN_ENRICHMENT_FAILURE = 'REFRESH_VULN_ENRICHMENT_FAILURE';

export const GET_VULNS_TOTAL_COUNT = 'GET_VULNS_TOTAL_COUNT';
export const EXPORT_CSV_MANAGE_REQUEST = 'EXPORT_CSV_MANAGE_REQUEST';
export const RESET_VULN_DETAIL = 'RESET_VULN_DETAIL';
