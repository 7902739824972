export const GENERAL_SERVICES_FETCHING = 'GENERAL_SERVICES_FETCHING';
export const GENERAL_SERVICES_FAIL = 'GENERAL_SERVICES_FAIL';
export const GENERAL_SERVICES_CLEAR_ERROR = 'GENERAL_SERVICES_CLEAR_ERROR';
export const GENERAL_SERVICES_GET_SUCCESS = 'GENERAL_SERVICES_GET_SUCCESS';
export const GENERAL_SERVICES_SELECTED = 'GENERAL_SERVICES_SELECTED';
export const GENERAL_SERVICES_UNSELECTED = 'GENERAL_SERVICES_UNSELECTED';
export const GENERAL_SERVICES_NEW_PIVOT = 'GENERAL_SERVICES_NEW_PIVOT';
export const GENERAL_SERVICES_SELECT_ALL = 'GENERAL_SERVICES_SELECT';
export const GENERAL_SERVICES_UNSELECT_ALL = 'GENERAL_SERVICES_UNSELECT';
export const GENERAL_SERVICES_SHOW_DETAIL = 'GENERAL_SERVICES_SHOW_DETAIL';
export const GENERAL_SERVICES_HIDE_DETAIL = 'GENERAL_SERVICES_HIDE_DETAIL';
export const GENERAL_SERVICES_SHOW_MODAL_DELETE = 'GENERAL_SERVICES_SHOW_MODAL_DELETE';
export const GENERAL_SERVICES_HIDE_MODAL_DELETE = 'GENERAL_SERVICES_HIDE_MODAL_DELETE';
export const GENERAL_SERVICES_DELETE_SUCCESS = 'GENERAL_SERVICES_DELETE_SUCCESS';
export const GENERAL_SERVICES_DELETE_FAIL = 'GENERAL_SERVICES_DELETE_FAIL';
export const GENERAL_SERVICES_DELETE_START = 'GENERAL_SERVICES_DELETE_START';
export const GENERAL_SERVICES_UPDATE_SUCCESS = 'GENERAL_SERVICES_UPDATE_SUCCESS';
export const GENERAL_SERVICES_RESET_EDIT = 'GENERAL_SERVICES_RESET_EDIT';
export const GENERAL_SERVICES_RESET_STATE = 'GENERAL_SERVICES_RESET_STATE';
export const GENERAL_SERVICES_ADD_SERVICE_SUCCESS = 'GENERAL_SERVICES_ADD_SERVICE_SUCCESS';
export const GET_ASSETS_BY_WORKSPACE_REQUEST = 'GET_ASSETS_BY_WORKSPACE_REQUEST';
export const GET_ASSETS_BY_WORKSPACE_SUCCESS = 'GET_ASSETS_BY_WORKSPACE_SUCCESS';
export const GET_ASSETS_BY_WORKSPACE_FAIL = 'GET_ASSETS_BY_WORKSPACE_FAIL';
export const GENERAL_SERVICES_SET_TAGS_SUCCESS = 'GENERAL_SERVICES_SET_TAGS_SUCCESS';
export const GENERAL_SERVICES_SET_TAGS_FAIL = 'GENERAL_SERVICES_SET_TAGS_FAIL';
export const GENERAL_SERVICES_SELECT_TOTAL = 'GENERAL_SERVICES_SELECT_TOTAL';
export const GENERAL_SERVICES_UNSELECT_TOTAL = 'GENERAL_SERVICES_UNSELECT_TOTAL';
export const GENERAL_SERVICES_UNSELECTED_ROW_FROM_SELECT_ALL = 'GENERAL_SERVICES_UNSELECTED_ROW_FROM_SELECT_ALL';
export const GENERAL_SERVICES_AUTOSELECT = 'GENERAL_SERVICES_AUTOSELECT';
