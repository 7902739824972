import React, { useEffect } from 'react';
import ReduxModal from 'Common/Components/ReduxModal';
import { MODAL_UPGRADE_LICENSE } from 'store/modals/modals';
import UpgradeLicense from 'Common/Components/UpgradeLicense';
import upgradeLicenseImage from 'Images/workspaces-license.png';
import { ACTIONS } from 'tracking/GA/constants';
import { DashboardTour } from 'Common/Components/Contextualization/Tour';
import DashboardLayout from '../DashboardLayout';
import { useDispatch } from 'react-redux';
import { resetFilters, clearFilters } from 'store/Filters/actions';

const DashboardContext = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(clearFilters('vulns'));
      dispatch(clearFilters('assets'));
      dispatch(clearFilters('vulnsAssets'));
      dispatch(resetFilters('dashboard'));
    };
  }, []);
  return (
    <>
      <DashboardLayout />
      <DashboardTour />
      <ReduxModal id={ MODAL_UPGRADE_LICENSE }>
        <UpgradeLicense id={ MODAL_UPGRADE_LICENSE } image={ upgradeLicenseImage } descriptionIntlId="upgradeLicense.workspace.description" trackingShow={ ACTIONS.showWorkspace } trackingClick={ ACTIONS.clickWorkspaces } />
      </ReduxModal>
    </>
  );
};

export default DashboardContext;
